/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import { FC } from 'react';
import { Button, Menu, MenuItem } from '@material-ui/core';
import type { ButtonProps } from '@material-ui/core';
import { darkDisabled } from 'src/colors';
import { usePopover } from '../hooks/use-popover';
import { ChevronDown as ChevronDownIcon } from '../icons/chevron-down';

interface ActionsMenuProps extends ButtonProps {
  actions?: any;
  email_address?: string;
  label?: string;
  trendsType?: string;
  component?: string;
  machine?: string;
  setIsclicked?: (id: boolean) => void;
}

export const ActionsMenu: FC<ActionsMenuProps> = (props) => {
  const { actions, label, trendsType, component, machine, setIsclicked, ...other } = props;
  const [anchorRef, open, handleOpen, handleClose] = usePopover();

  const handleData = () => {
    let data = '';
    if (trendsType === 'users') {
      if (!localStorage.getItem('trendsUsers')?.length) {
        localStorage.setItem('trendsUsers', label);
      } else {
        data = localStorage.getItem('trendsUsers');
      }
    }
    if (trendsType === 'machine' && component === 'all') {
      if (!localStorage.getItem('trendsMachine')?.length) {
        localStorage.setItem('trendsMachine', label);
      } else {
        data = localStorage.getItem('trendsMachine');
      }
    }
    if (trendsType === 'machine' && component === 'active') {
      data = machine || 'All Data Managers';
    }
    if (trendsType === 'ranges') {
      if (!localStorage.getItem('trendsRanges')?.length) {
        localStorage.setItem('trendsRanges', label);
      } else {
        data = localStorage.getItem('trendsRanges');
      }
    }
    return data;
  };

  return (
    <>
      <Button
        endIcon={<ChevronDownIcon fontSize="small" />}
        onClick={handleOpen}
        size="small"
        variant="contained"
        ref={anchorRef}
        sx={{
          color: 'text.secondary',
        }}
        {...other}
      >
        {handleData()}
      </Button>
      <Menu
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        open={open}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        {
          // actions?.length ?
           actions?.length > 0 && actions?.map((item) => (
             <MenuItem
               disabled={component === 'active' && actions.length === 1 && item.label === 'All Data Managers'}
               key={item.label}
               sx={{
                 '&.MuiMenuItem-root:active': {
                   backgroundColor: (theme) => theme.palette.secondary.main,
                 },
                 '&.Mui-disabled': {
                   color: darkDisabled.contrast
                 }
               }}
               onClick={() => {
                 if (item.onClick) {
                   item.onClick();
                   if (trendsType === 'users') {
                     localStorage.setItem('trendsUsers', item.label);
                   } else if (trendsType === 'machine' && component === 'all') {
                     localStorage.setItem('trendsMachine', item.label);
                   } else if (trendsType === 'ranges') {
                     localStorage.setItem('trendsRanges', item.label);
                   } else if (trendsType === 'machine' && component === 'active') {
                     setIsclicked(true);
                   }
                 }
                 handleClose();
               }}
             >
               {
                  component === 'active' ? (
                    (actions?.length === 1 && item.label === 'All Data Managers') ? 'No active data manager' : item.label
                  ) : item.label
                }
             </MenuItem>
           ))
        }
      </Menu>
    </>
  );
};

ActionsMenu.defaultProps = {
  label: 'Actions'
};

ActionsMenu.propTypes = {
  actions: PropTypes.array.isRequired,
  label: PropTypes.string,
  email_address: PropTypes.string,
  trendsType: PropTypes.string,
  component: PropTypes.string,
  machine: PropTypes.string,
  setIsclicked: PropTypes.func
};
