/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unused-vars */
import type { Project } from 'src/types/project';
import type { ProjectFolder } from 'src/types/project-folder';
import type { ProjectDetailed } from 'src/types/project-detailed';
import type { ProjectSchedule } from 'src/types/project-schedule';
import { ApiHelper } from 'src/shared/utils/api-helper';
// import axios from 'axios';
import { applySort } from 'src/shared/utils/commonFunctions';
import { throttle } from '../config';
import { applyFilters } from '../utils/apply-filters';
import { applyPagination } from '../utils/apply-pagination';
import { wait } from '../utils/wait';

interface Options {
  filters?: Array<{ operator: string; property: string; value: any }>;
  sort?: string;
  sortBy?: string;
  page?: number;
  pageSize?: number;
  query?: string;
  statusView?: string;
  jobType?: any;
  collection?: string;
  tags?: any;
  isTagsSelected?: boolean
}

interface PayloadOption {
  statusView?: 'all' | 'active' | 'failure';
}

const includesLowerCase = (str, strsToFind) => !!strsToFind.find((s) => str.toLowerCase().includes(s.toLowerCase()));

class ProjectApi {
  doesStatusBelongInStatusView(status, statusView) {
    if (typeof statusView === 'undefined' || statusView === 'allJobs') {
      return true;
    }

    if (statusView === 'successJobs' && !includesLowerCase(status, ['success'])) {
      return false;
    }

    if (statusView === 'issuesJobs' && !includesLowerCase(status, ['failed', 'warning'])) {
      return false;
    }

    if (statusView === 'activeJobs' && includesLowerCase(status, [])) {
      return false;
    }
    if (statusView === 'failure' && !includesLowerCase(status, ['failed', 'warning'])) {
      return false;
    }

    return true;
  }

  getProjectsByFilters(
    options: Options,
    data: Project[]
  ): { projects: Project[]; projectsCount: number, totalRecords: Project[] } {
    const { filters, sort, sortBy, page, pageSize, query, statusView, jobType, collection, tags, isTagsSelected } = options;

    const queryValue = {
      allJobs: sessionStorage.getItem('queryAll') || '',
      activeJobs: sessionStorage.getItem('queryActive') || '',
      failure: sessionStorage.getItem('queryFailure') || '',
    };

    /*
     NOTE: Query, filter, sort and pagination are operation meant to be executed on the server.
     Since this does not connect to a real backend, we simulate these operations.
     */

    const queriedProjects = data.filter((_project) => {
      // If query exists, it looks only in customer full name field
      if (
        !!query
        && !_project.jobName?.toLowerCase().includes(query?.toLowerCase())
      ) {
        return false;
      }

      // No need to look for any resource fields
      return this.doesStatusBelongInStatusView(_project.status, statusView);
    });

    const jobTypeProjectsFilter = queriedProjects.filter((_project) => {
      if (jobType?.includes('All Jobs')) {
        return true;
      }
      if (
        jobType?.includes('Retrieve')
        && _project.jobType?.toLowerCase().includes('Retrieve'.toLowerCase())
      ) {
        return true;
      }
      if (jobType?.includes('Store') && _project.jobType?.toLowerCase().includes('Store'.toLowerCase())) {
        return true;
      }
      if (
        jobType?.includes('Duplicate')
        && _project.jobType?.toLowerCase().includes('Duplicate'.toLowerCase())
      ) {
        return true;
      }
      if (jobType?.includes('Probe') && _project.jobType?.toLowerCase().includes('Probe'.toLowerCase())) {
        return true;
      }
      if (jobType?.includes('Index') && _project.jobType?.toLowerCase().includes('Index'.toLowerCase())) {
        return true;
      }
      if (
        jobType?.includes('Verification')
        && _project.jobType?.toLowerCase().includes('Verify'.toLowerCase())
      ) {
        return true;
      }
      if (
        jobType?.includes('Deletion')
        && _project.jobType?.toLowerCase().includes('Delete'.toLowerCase())
      ) {
        return true;
      }
      return false;
    });

    const collectionFilter = jobTypeProjectsFilter.filter((_project) => {
      if (collection === _project?.repoGroup) {
        return true;
      }
      if (collection === 'Collections') {
        return jobTypeProjectsFilter;
      }
      return false;
    });

    const taggedFilter = isTagsSelected ? collectionFilter.filter((_project) => {
      if (tags?.includes(_project?.jobGuid)) {
        return true;
      }
      return false;
    }) : collectionFilter;

    const filteredProjects = applyFilters(
      taggedFilter,
      filters
    );
    const { requiredRows, extraRows }: any = filteredProjects?.reduce((records, e) => {
      if (['date', 'scanned'].includes(sortBy)) {
        if (!e.status.toLowerCase().includes('never started')) {
          records.requiredRows.push(e);
        } else {
          records.extraRows.push(e);
        }
      }

      if (sortBy === 'repoGroup') {
        if (!e.repoGroup.includes('No Group')) {
          records.requiredRows.push(e);
        } else {
          records.extraRows.push(e);
        }
      }

      if (sortBy === 'jobTags') {
        if (e.jobTags !== '-') {
          records.requiredRows.push(e);
        } else {
          records.extraRows.push(e);
        }
      }

      return records;
    }, { requiredRows: [], extraRows: [] });

    const sortedProjects = applySort(['date', 'scanned', 'repoGroup', 'jobTags']?.includes(sortBy) ? requiredRows : filteredProjects, sort, sortBy);
    const totalSortedData = ['date', 'scanned', 'repoGroup', 'jobTags']?.includes(sortBy) ? sort === 'asc' ? [...extraRows, ...sortedProjects] : [...sortedProjects, ...extraRows] : sortedProjects;
    const paginatedProjects = applyPagination(totalSortedData, page, pageSize);

    return {
      totalRecords: totalSortedData,
      projects: paginatedProjects,
      projectsCount: filteredProjects.length,
    };
  }

  async getProjectsByStatusFilter(options: PayloadOption): Promise<{ projects: Project[] }> {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get(`/wildfly/jobs?statusfilter=${options.statusView}`);
    const projects = res.data.sort(
      (a, b) => new Date(b?.lastChangeDate).getTime() - new Date(a?.lastChangeDate).getTime()
    );

    return Promise.resolve({
      projects,
    });
  }

  async getProjects(options?: Options): Promise<{ projects: Project[]; projectsCount: number }> {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get('/wildfly/jobs?statusfilter=all');
    const projects = res.data;

    const { filters, sort, sortBy, page, pageSize, query, statusView, jobType } = options;

    /*
     NOTE: Query, filter, sort and pagination are operation meant to be executed on the server.
     Since this does not connect to a real backend, we simulate these operations.
     */

    const queriedProjects = projects.filter((_project) => {
      // If query exists, it looks only in customer full name field
      if (!!query && !_project.jobName?.toLowerCase().includes(query.toLowerCase())) {
        return false;
      }

      // No need to look for any resource fields
      return this.doesStatusBelongInStatusView(_project.status, statusView);
    });

    const jobTypeProjectsFilter = queriedProjects.filter((_project) => {
      if (jobType?.includes('All Jobs')) {
        return true;
      }
      if (
        jobType?.includes('Retrieve')
        && _project.jobType?.toLowerCase().includes('Retrieve'.toLowerCase())
      ) {
        return true;
      }
      if (jobType?.includes('Store') && _project.jobType?.toLowerCase().includes('Store'.toLowerCase())) {
        return true;
      }
      if (
        jobType?.includes('Duplicate')
        && _project.jobType?.toLowerCase().includes('Duplicate'.toLowerCase())
      ) {
        return true;
      }
      if (jobType?.includes('Probe') && _project.jobType?.toLowerCase().includes('Probe'.toLowerCase())) {
        return true;
      }
      if (jobType?.includes('Index') && _project.jobType?.toLowerCase().includes('Index'.toLowerCase())) {
        return true;
      }
      return false;
    });

    const filteredProjects = applyFilters(
      statusView === 'allJobs' ? jobTypeProjectsFilter : queriedProjects,
      filters
    );
    const sortedProjects = applySort(filteredProjects, sort, sortBy);
    const paginatedProjects = applyPagination(sortedProjects, page, pageSize);

    return Promise.resolve({
      projects: paginatedProjects,
      projectsCount: filteredProjects.length,
    });
  }

  async getActiveProjects(
    options: Options
  ): Promise<{ projects: Project[]; projectsCount: number }> {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get('/wildfly/jobs?statusfilter=active');
    const projects = res.data;

    const { filters, sort, sortBy, page, pageSize, query, statusView } = options;

    /*
     NOTE: Query, filter, sort and pagination are operation meant to be executed on the server.
     Since this does not connect to a real backend, we simulate these operations.
     */

    const queriedProjects = projects.filter((_project) => {
      // If query exists, it looks only in customer full name field
      if (!!query && !_project.jobName?.toLowerCase().includes(query.toLowerCase())) {
        return false;
      }

      // No need to look for any resource fields
      return this.doesStatusBelongInStatusView(_project.status, statusView);
    });
    const filteredProjects = applyFilters(queriedProjects, filters);
    const sortedProjects = applySort(filteredProjects, sort, sortBy);
    const paginatedProjects = applyPagination(sortedProjects, page, pageSize);

    return Promise.resolve({
      projects: paginatedProjects,
      projectsCount: filteredProjects.length,
    });
  }

  async getFailureProjects(
    options: Options
  ): Promise<{ projects: Project[]; projectsCount: number }> {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get('/wildfly/jobs?statusfilter=failure');
    const projects = res.data;

    const { filters, sort, sortBy, page, pageSize, query, statusView } = options;

    /*
     NOTE: Query, filter, sort and pagination are operation meant to be executed on the server.
     Since this does not connect to a real backend, we simulate these operations.
     */

    const queriedProjects = projects.filter((_project) => {
      // If query exists, it looks only in customer full name field
      if (!!query && !_project.jobName?.toLowerCase().includes(query.toLowerCase())) {
        return false;
      }

      // No need to look for any resource fields
      return this.doesStatusBelongInStatusView(_project.status, statusView);
    });
    const filteredProjects = applyFilters(queriedProjects, filters);
    const sortedProjects = applySort(filteredProjects, sort, sortBy);
    const paginatedProjects = applyPagination(sortedProjects, page, pageSize);

    return Promise.resolve({
      projects: paginatedProjects,
      projectsCount: filteredProjects.length,
    });
  }

  async postStartProjects(startProjectsPayload: object): Promise<ProjectDetailed> {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.post('/wildfly/startProjects', startProjectsPayload);
    return res.data;
  }

  async postStopProjects(stopProjectsPayload: object): Promise<ProjectDetailed> {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.post('/wildfly/stopProjects', stopProjectsPayload);
    return res.data;
  }

  async postEnableDisableProjects(payload: object): Promise<ProjectDetailed> {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.post('/wildfly/enableDisableProjects', payload);
    return res.data;
  }

  async postFailureLogs(payload: object): Promise<ProjectDetailed> {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.post('/wildfly/jobLogging', payload);
    return res.data;
  }

  async postClearJobHistory(payload: string): Promise<ProjectDetailed> {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.post(`/wildfly/clearJobHistory?jobGuid=${payload}`, payload);
    return res.data;
  }

  async postClearAllTicket(payload: object): Promise<ProjectDetailed> {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.post('/wildfly/ticket/clearTickets', payload);
    return res.data;
  }

  async postRemoveTickets(payload: object): Promise<ProjectDetailed> {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.post('/wildfly/ticket/removeTickets', payload);
    return res.data;
  }

  async postRemoveJobHistory(payload: object): Promise<ProjectDetailed> {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.post('/wildfly/removeJobHistory', payload);
    return res.data;
  }

  async postMarkFiles(payload: object): Promise<ProjectDetailed> {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.post('/wildfly/browse/markFiles', payload);
    return res.data;
  }

  async postRemoveOnDemandFiles(payload: Object, setToastHandling: any) {
    if (throttle) await wait(throttle);
    await ApiHelper.post('/wildfly/removeOnDemandFiles', payload)
      .then((res) => {
        setToastHandling({
          status: true,
          message: `${res?.data[0]?.message}`,
        });
        return res.data;
      })
      .catch((error) => {
        setToastHandling({
          status: false,
          message: `${error?.response?.data?.message}`,
        });
      });
  }

  async postOnDemandAllFiles(payload: object): Promise<ProjectDetailed> {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.deleteRec('/wildfly/onDemandAllFiles', payload);
    return res.data;
  }

  async postSaveFolderOptions(payload: object): Promise<ProjectDetailed> {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.post('/wildfly/saveFolderOptions', payload);
    return res.data;
  }

  async postReindexJob(payload: object): Promise<ProjectDetailed> {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.post('/wildfly/reindexJob', payload);
    return res.data;
  }

  async postRefreshTickets(payload: object): Promise<ProjectDetailed> {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.post('/wildfly/ticket/refreshTickets', payload);
    return res.data;
  }

  async postCheckTicketLock(payload: object): Promise<ProjectDetailed> {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.post('/wildfly/ticket/checkTicketLock', payload);
    return res.data;
  }

  async postApplyOptionsTemplate(payload: object): Promise<ProjectDetailed> {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.post('/optionsTemplate/apply', payload);
    return res.data;
  }

  async getDirectoryStats(dirPath: string, repoGuid: string) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get(
      `/wildfly/directoryStats?dirPath=${dirPath}&repoGuid=${repoGuid}`
    );
    return res.data;
  }

  async getIndexerStatus(jobName) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get(`/wildfly/jobs/indexerStatus/${jobName}`);
    return res.data;
  }

  async getUser() {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get('/wildfly/users');
    return res.data;
  }

  async getMachine() {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get('/wildfly/clientMachines');
    return res.data;
  }

  async getMachineForUser() {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get('/wildfly/jobWizardClients');
    return res.data;
  }

  async deleteClient(params) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.deleteApi(`/wildfly/client/${params}`);
    return res.data;
  }

  // users API
  async getUserList() {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get('/wildfly/users/list');
    return res.data;
  }

  async getUserDetails(userName: string) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get(`/wildfly/users/detail?itemId=${userName}`);
    return res.data;
  }

  async addUserDetails(payload) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.post('/wildfly/users/detail', { ...payload });
    return res.data;
  }

  async deleteUserDetails(payload) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.deleteRec('/wildfly/users/detail', { ...payload });
    return res.data;
  }

  async updateUserDetails(payload) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.put('/wildfly/users/detail', { ...payload });
    return res.data;
  }

  // Groups API
  async getGroupList() {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get('/wildfly/group/list');
    return res.data;
  }

  async getGroupDetails(groupName: string) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get(`/wildfly/group/detail?itemId=${groupName}`);
    return res.data;
  }

  async addGroupDetails(payload) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.post('/wildfly/group/detail', { ...payload });
    return res.data;
  }

  async deleteGroupDetails(payload) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.deleteRec('/wildfly/group/detail', { ...payload });
    return res.data;
  }

  async updateGroupDetails(payload) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.put('/wildfly/group/detail', { ...payload });
    return res.data;
  }

  // License
  async activateLicense(payload) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.post('/wildfly/client/license/activate', { ...payload });
    return res.data;
  }

  async deActivateLicense(payload) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.post('/wildfly/client/license/deactivate', { ...payload });
    return res.data;
  }

  async updateClientLicense(payload) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.put('/wildfly/client/license/update', { ...payload });
    return res.data;
  }

  async enableDisableClient(payload) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.post('/wildfly/client/toggle', { ...payload });
    return res.data;
  }

  async updateClient(clientMachine, payload) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.put(`/wildfly/clients/${clientMachine}`, { ...payload });
    return res.data;
  }

  async getProjectTabs(jobGuid: string): Promise<ProjectDetailed> {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get(`/wildfly/projectTabs?jobGuid=${jobGuid}`);
    return res.data;
  }

  async getArchiveClient(repoGuid: string): Promise<ProjectDetailed> {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get(`/wildfly/ticket/archiveClient/${repoGuid}`);
    return res.data;
  }

  async getTicketConfiguration(
    repoGuid: string,
    jobGuid: string,
    jobType: string
  ): Promise<ProjectDetailed> {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get(
      `/wildfly/ticket/ticketConfiguration?repoGuid=${repoGuid}&jobGuid=${jobGuid}&jobType=${jobType}`
    );
    return res.data;
  }

  async getAleFileInfo(clientGuid: string, folderPath: string): Promise<ProjectDetailed> {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get(
      `/wildfly/ticket/aleFileInfo?clientGuid=${clientGuid}&folderPath=${folderPath}`
    );
    return res.data;
  }

  async postConvertTickets(payload: object): Promise<ProjectDetailed> {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.post('/wildfly/ticket/convert', payload);
    return res.data;
  }

  async getOptions(jobGuid: string): Promise<ProjectDetailed> {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get(`/wildfly/jobOptions/${jobGuid}`);
    return res.data;
  }

  // async getCloudCostBaseUrl() {
  //   if (throttle) await wait(throttle);
  //   const res = await ApiHelper.get('/cloudCostBaseURL');
  //   return res.data;
  // }

  // async putCloudCostBaseUrl(payload) {
  //   if (throttle) await wait(throttle);
  //   const res = await ApiHelper.put('/cloudCostBaseURL', { ...payload });
  //   return res.data;
  // }

  // async getBrowse(repoGuid: string, searchTags: string, includedeletes: boolean, path: string, hitsPerPage, currentPage) {
  async getBrowse(payload) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.post('/wildfly/browse/fileOrFolderList', { ...payload });
    // const res = await ApiHelper.get(
    //   `/wildfly/browse/getFileOrFolderList?repoGuid=${repoGuid}&searchTags=${searchTags}&includedeletes=${includedeletes}&path=${encodeURIComponent(
    //     path
    //   )}&hitsPerPage=${hitsPerPage}&currentPage=${currentPage}`
    // );
    return res.data;
  }

  async postCatalogsBrowse(payload: object) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.post('/catalogs/browse', payload);
    return res.data;
  }

  async getBrowseSearch(repoGuid: string, searchTerms: string, hitsPerPage, currentPage) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get(
      `/wildfly/browse/searchFiles?repoGuid=${repoGuid}&searchTerms=${searchTerms}&hitsPerPage=${hitsPerPage}&currentPage=${currentPage}`
    );
    return res.data;
  }

  async getSearchClips(payload, cancelToken) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.post('/wildfly/searchClips', payload, cancelToken);
    return res.data;
  }

  async getCatalogSearch(payload: object, cancelToken) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.post('/catalogs/search', payload, cancelToken);
    return res.data;
  }

  async getFrameioFolderList(clientMachine: string, configName: string, projectId: string, assetId: string, folderPath: string): Promise<ProjectDetailed> {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get(`/wildfly/remoteConfig/frameioFolderList?clientMachine=${clientMachine}&configName=${configName}&projectId=${projectId}&assetId=${assetId}&folderPath=${folderPath}`);
    return res.data;
  }

  async getProject(jobGuid: string): Promise<ProjectDetailed> {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get(`/wildfly/jobs/${jobGuid}`);
    return res.data;
  }

  async getEvolutionProjects(configName: string, clientMachine: string): Promise<ProjectDetailed> {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get(`/wildfly/evolutionProjects?configName=${configName}&clientMachine=${clientMachine}`);
    return res.data;
  }

  async getClientLicenseFeature(clientguid: string): Promise<ProjectDetailed> {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get(`/wildfly/client/license?clientMachine=${clientguid}`);
    return res.data;
  }

  async getAllTickets(jobGuid: string, repoGuid: string, jobType: string): Promise<any> {
    const res = await ApiHelper.get(
      `/wildfly/ticket/fetchAllTickets?repoGuid=${repoGuid}&jobGuid=${jobGuid}&jobType=${jobType}`
    );
    return res?.data ? res?.data : {};
  }

  async getTicketFileList(
    jobGuid: string,
    repoGuid: string,
    jobType: string,
    ticketType: string,
    ticketFileName: string
  ): Promise<any> {
    const res = await ApiHelper.get(
      `/wildfly/ticket/getTicketFileList?jobType=${jobType}&ticketType=${ticketType}&ticketFileName=${ticketFileName}&repoGuid=${repoGuid}&jobGuid=${jobGuid}`
    );
    return res?.data ? res?.data : {};
  }

  async getProjectFolders(jobGuid: string): Promise<ProjectFolder[]> {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get(`/wildfly/jobs/folders/${jobGuid}`);
    return res.data;
  }

  async getAvidMediaFolderListing(clientMachine: string, folderPath: string, configName: string) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get(
      `/wildfly/remoteConfig/avidMediaFolderList?clientMachine=${clientMachine}&folderPath=${encodeURIComponent(
        folderPath
      )}&configName=${configName}`
    );
    return res.data;
  }

  async getFetchAvidEditBays(clientMachine: string, folderPath: string, configName: string) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get(
      `/wildfly/remoteConfig/editBays?clientMachine=${clientMachine}&folderPath=${encodeURIComponent(
        folderPath
      )}&configName=${configName}`
    );
    return res.data;
  }

  async getLoadJobSource(jobGuid: string): Promise<ProjectFolder[]> {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get(`/wildfly/loadJobSource/${jobGuid}`);
    return res.data;
  }

  async getMediaPathList(clientMachineId: string, amaSet: number, nativeSet: number): Promise<ProjectFolder[]> {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get(`/wildfly/ticket/mediaPathList?clientMachineId=${clientMachineId}&amaSet=${amaSet}&nativeSet=${nativeSet}`);
    return res.data;
  }

  async deleteMediaPath(payload) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.deleteRec('/wildfly/ticket/mediaPath', payload);
    return res.data;
  }

  async getJobAutoXmlData(jobGuid: string) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get(`/wildfly/jobAutoXML/${jobGuid}`);
    return res.data;
  }

  async verifyMediaPath(payload: object) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.post('/wildfly/ticket/verifyMediaPath', payload);
    return res.data;
  }

  async convertTickets(payload: object) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.post('/wildfly/ticket/convert', payload);
    return res.data;
  }

  async postSaveAutomation(payload: object) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.post('/wildfly/saveAutomation', payload);
    return res.data;
  }

  async getProjectHistory(jobGuid: string): Promise<ProjectDetailed> {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get(`/wildfly/jobs/history/${jobGuid}`);
    return res.data;
  }

  async getProjectHistoryByFilter(filterQueryParams: any): Promise<any> {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get('/wildfly/jobs/history', { params: filterQueryParams });
    return res.data;
  }

  async getJobRunLogs(
    jobGuid: string,
    repoName: string,
    trackingGuid: string
  ): Promise<ProjectDetailed> {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get(
      `/wildfly/getJobRunLogs?jobGuid=${jobGuid}&repoName=${repoName}&trackingGuid=${trackingGuid}`
    );
    return res.data;
  }

  async getViewJobHistory(
    jobGuid: string,
    trackingGuid: string,
    repoGuid: string
  ): Promise<ProjectDetailed> {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get(
      `/wildfly/viewJobHistory?jobGuid=${jobGuid}&trackingGuid=${trackingGuid}&repoGuid=${repoGuid}`
    );
    return res.data;
  }

  async setProjectSettings(jobGuid: string, newProjectSettings: any): Promise<ProjectDetailed> {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.put(`/wildfly/jobs/settings/${jobGuid}`, newProjectSettings);
    return res.data;
  }

  async getProjectSchedule(jobGuid: string): Promise<ProjectSchedule> {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get(`/wildfly/jobs/schedule/${jobGuid}`);
    return { ...res.data, scheduleType: res.data.scheduleType.toLowerCase() };
  }

  async getLoadProjectSchedule(jobGuid: string): Promise<ProjectSchedule> {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get(`/wildfly/loadJobSchedule/${jobGuid}`);
    return { ...res.data, scheduleType: res.data.scheduleType.toLowerCase() };
  }

  async setProjectSchedule(newSchedule: any) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.put('/wildfly/jobs/schedule', newSchedule);
    return res.data;
  }

  async getActiveRunStats() {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get('/wildfly/activeRunStats');
    return res.data;
  }

  async getConfigProviderList(configProvider: string) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get(`/wildfly/remoteConfig/list?configProvider=${configProvider}`);
    return res.data;
  }

  async getAvideodFilecatalystFolderList(
    clientMachine: string,
    vendorName: string,
    vendorConfig: string,
    folderPath: string
  ) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get(
      `/wildfly/remoteConfig/avidEodAndfileCatalystFolderList?clientMachine=${clientMachine}&vendorName=${vendorName}&vendorConfig=${vendorConfig}&folderPath=${encodeURIComponent(
        folderPath
      )}`
    );
    return res.data;
  }

  async getJobMonitoring(jobId: number) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get(`/wildfly/jobMonitoring/${jobId}`);
    return res.data;
  }

  async getProjectWorkflows() {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get('/wildfly/getProjectWorkflows');
    return res.data;
  }

  async getProjectWorkflowsTags() {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get('/wildfly/getProjectWorkflowsTags');
    return res.data;
  }

  async getProjectTypes() {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get('/wildfly/projectTypes');
    return res.data;
  }

  async getProjectTags() {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get('/wildfly/projectTags');
    return res.data;
  }

  async getUserGroups() {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get('/usergroups');
    return res.data;
  }

  async getProjectGroups() {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get('/wildfly/projectGroups');
    return res.data;
  }

  async getSourceFolders(clientMachine: string, path: string) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get(`/wildfly/browse/${clientMachine}?path=${path}`);
    return res.data;
  }

  async getWanFolders(folderPath: string, clientMachine: string, configName: string) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get(
      `/wildfly/remoteConfig/wanFolderList?folderPath=${folderPath}&clientMachine=${clientMachine}&configName=${configName}`
    );
    return res.data;
  }

  async getAtempoFolders(folderPath: string, clientMachineId: string, vendorConfig: string) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get(
      `/wildfly/atempoFolderList?folderPath=${folderPath}&clientMachineId=${clientMachineId}&vendorConfig=${vendorConfig}`
    );
    return res.data;
  }

  async getCloudFolderList(
    vendorConfig: string,
    bucketName: string,
    clientMachineId: string,
    folderPath: string,
  ) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get(
      `/wildfly/remoteConfig/cloudFolderList?bucketName=${bucketName}&folderPath=${folderPath}&clientMachineId=${clientMachineId}&vendorConfig=${vendorConfig}`
    );
    return res?.data ? res?.data : {};
  }

  async getCloudConfigs(clientMachine: string) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get(`/wildfly/cloud/configs/${clientMachine}`);
    return res.data;
  }

  async getCloudBuckets(clientMachine: string, configName: string) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get(`/wildfly/cloud/buckets/${clientMachine}/${configName}`);
    return res.data;
  }

  async getRetrieveFiles(payload) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.post('/wildfly/ticket/restoreFiles', { ...payload });
    return res.data;
  }

  async getRestoreClips(payload) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.post('/wildfly/restoreClips', { ...payload });
    return res.data;
  }

  async getProjectCollection() {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get('/wildfly/collections');
    return res.data;
  }

  async userResetPassword(payload) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.post('/wildfly/users/resetPassword', { ...payload });
    return res.data;
  }

  async postCatalogTickets(payload) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.post('/catalog/tickets', { ...payload });
    return res.data;
  }

  async refreshUserToken(payload) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.post('/wildfly/users/refreshToken', { ...payload });
    return res.data;
  }

  async addProjectCollection(payload) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.post('/wildfly/collections/add', { ...payload });
    return res.data;
  }

  async editProjectCollection(payload) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.put('/wildfly/repoGroupName', { ...payload });
    return res.data;
  }

  async addSingleProjectCollection(payload) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.post('/wildfly/collections/addSingle', { ...payload });
    return res.data;
  }

  async createFolder(payload) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.post('/wildfly/createFolder', { ...payload });
    return res.data;
  }

  async createFrameIoFolder(payload) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.post('/wildfly/createFrameIoFolder', { ...payload });
    return res.data;
  }

  async createCloudFolder(payload) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.post('/wildfly/createCloudFolder', { ...payload });
    return res.data;
  }

  async deleteProjectCollection(params) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.deleteApi(`/wildfly/collections/${params}`);
    return res.data;
  }

  async deleteJob(jobGuid) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.deleteApi(`/wildfly/deleteJob/${jobGuid}`);
    return res.data;
  }

  async getCollectionProject(excludeAnalytics: string) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get(`/wildfly/jobList?excludeAnalytics=${excludeAnalytics}`);
    return res.data;
  }

  async getStoreJobDetails(projectName: string) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get(`/wildfly/storeJobDetails?projectName=${projectName}`);
    return res.data;
  }

  async getAllJobs(mediaType = 'DISK') {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get(`/wildfly/projects?mediaType=${mediaType}`);
    return res.data;
  }

  async updateCollection(groupName, payload) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.put(`/wildfly/collections/${groupName}`, { ...payload });
    return res.data;
  }

  async getFilterGroups() {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get('/wildfly/filter/filterGroups');
    return res.data;
  }

  async getFilterFileExtensions(filterGroupId) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get(`/wildfly/filter/fileExtentions/${filterGroupId}`);
    return res.data;
  }

  async addCustomFileExtensions(customExtensions) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.post('/wildfly/filter/addCustomExtensions', { customExtensions });
    return res.data;
  }

  async getOptionsTemplates(projectTypeName: string, projectPrefix: string) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get(
      `/optionsTemplates?projectTypeName=${projectTypeName}&projectPrefix=${projectPrefix}`
    );
    return res.data;
  }

  async getOptionsTemplatesDetails(templateId: string) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get(`/optionsTemplate?templateId=${templateId}`);
    return res.data;
  }

  async postProjectStats(payload: object): Promise<ProjectDetailed> {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.post('/wildfly/projectStats', payload);
    return res.data;
  }

  async saveOptionsTemplate(reqBody: any) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.post('/optionsTemplate', reqBody);
    return res?.data ? res?.data : {};
  }

  async saveLoginPersonalize(reqBody: any) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.post('/loginPersonalize', reqBody);
    return res?.data ? res?.data : {};
  }

  async saveLoginPreferences(reqBody: any) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.post('/loginPersonalize/savePreference', reqBody);
    return res?.data ? res?.data : {};
  }

  async getLoginPersonalize() {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get('/loginPersonalize');
    return res?.data ? res?.data : {};
  }

  async uploadDefaultImageLogo(reqBody: any) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.post('/loginPersonalize/default', reqBody);
    return res?.data ? res?.data : {};
  }

  async getLoginPersonalizeLoginScreen() {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get('/loginPersonalize');
    return res?.data ? res?.data : {};
  }

  async deleteLoginPersonalize(payload: object): Promise<ProjectDetailed> {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.deleteRec('/loginPersonalize', payload);
    return res.data;
  }

  async saveNewProject(reqBody: any) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.post('/wildfly/saveNewProject', reqBody);
    return res?.data ? res?.data : {};
  }

  async updateLtoTape(reqBody: any) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.put('/wildfly/updateLtoTape', reqBody);
    return res?.data ? res?.data : {};
  }

  async userGroups() {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get('/usergroups');
    return res?.data ? res?.data : {};
  }

  async updateProjectOption(payload) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.put('/wildfly/options/projectOptions', payload);
    return res.data;
  }

  async getProbeAnalysis(jobGuid: string, runId: string, reqBody: any) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.post(
      `/probeAnalysis/filter?jobGuid=${jobGuid}&runId=${runId}`,
      reqBody
    );
    return res?.data ? res?.data : {};
  }

  async getProbeSummary(jobGuid: string) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get(`/probeAnalysis/summary?jobGuid=${jobGuid}`);
    return res?.data ? res?.data : {};
  }

  async getDuplicateAnalysis(jobGuid: string, runId: string, reqBody: any, lastRecordId, lastRecordValue) {
    if (throttle) await wait(throttle);
    let url = `/duplicateAnalysis/filter?jobGuid=${jobGuid}&runId=${runId}`;
    url += lastRecordId?.length ? `&lastRecordId=${lastRecordId}` : '';
    url += lastRecordValue?.toString()?.length ? `&lastRecordValue=${lastRecordValue}` : '';
    const res = await ApiHelper.post(
      url,
      reqBody
    );
    return res?.data ? res?.data : {};
  }

  async getDuplicateAnalysisCount(jobGuid: string, runId: string, reqBody: any) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.post(`/duplicateAnalysis/count?jobGuid=${jobGuid}&runId=${runId}`, reqBody);
    return res?.data ? res?.data : {};
  }

  async getDuplicateSummary(jobGuid: string) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get(`/duplicateAnalysis/summary?jobGuid=${jobGuid}`);
    return res?.data ? res?.data : {};
  }

  async getStorageAnalysis(jobGuid: string, runId: string, reqBody: any, lastRecordId, lastRecordValue) {
    if (throttle) await wait(throttle);
    let url = `/storageAnalysis/filter?jobGuid=${jobGuid}&runId=${runId}`;
    url += lastRecordId?.length ? `&lastRecordId=${lastRecordId}` : '';
    url += lastRecordValue?.toString()?.length ? `&lastRecordValue=${lastRecordValue}` : '';
    const res = await ApiHelper.post(
      url,
      reqBody
    );
    return res?.data ? res?.data : {};
  }

  async getStorageAnalysisCount(jobGuid: string, runId: string, reqBody: any) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.post(`/storageAnalysis/count?jobGuid=${jobGuid}&runId=${runId}`, reqBody);
    return res?.data ? res?.data : {};
  }

  async getStorageSummary(jobGuid: string) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get(`/storageAnalysis/summary?jobGuid=${jobGuid}`);
    return res?.data ? res?.data : {};
  }

  async getEmailSetting() {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get('/wildfly/emailSettings');
    return res?.data ? res?.data : {};
  }

  async addEmailSettingsDetails(payload) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.post('/wildfly/emailSettings', { ...payload });
    return res.data;
  }

  async getOldUIData() {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get('/wildfly/switchUI?UIName=wildfly');
    return res?.data ? res?.data : {};
  }

  async updateProjectName(payload) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.put('/wildfly/jobDisplayName', payload);
    return res.data;
  }

  async updateDataManager(payload) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.post('/wildfly/clients/moveJob', payload);
    return res.data;
  }

  async getSdnaVersion() {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get('/wildfly/sdnaVersion');
    return res?.data ? res?.data : {};
  }

  async getSavedFilters(filterType, subType) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get(
      `/savedFilter?filterType=${filterType}&subFilterType=${subType}`
    );
    return res.data;
  }

  async saveNewFilter(payload) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.post('/savedFilter', payload);
    return res.data;
  }

  async getFilterDetail(filterId) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get(`/savedFilter/${filterId}`);
    return res.data;
  }

  async updateFilterDetail(filterId, payload) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.put(`/savedFilter/${filterId}`, payload);
    return res.data;
  }

  async deleteFilter(filterId = '') {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.deleteApi(`/savedFilter/${filterId}`);
    return res.data;
  }

  async getMdataTemplateList(templateType = '') {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get(`/metadata/templates?templateType=${templateType}`);
    return res.data;
  }

  async getMdataTemplateDetails(id: string) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get(`/metadata/template/${id}`);
    return res.data;
  }

  async saveMdataTemplate(payload = {}) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.post('/metadata/template', payload);
    return res.data;
  }

  async updateMdataTemplate(payload = {}) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.post('/metadata/template', payload);
    return res.data;
  }

  async assignUsersToAlltemplates(payload = {}) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.post('/metadata/template/assignUsers', payload);
    return res.data;
  }

  async deleteMdataTemplates(ids = []) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.deleteRec('/metadata/templates', { ids });
    return res.data;
  }

  async getCurrencyList() {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get('/metadata/templates/currency');
    return res.data;
  }

  async getSavedSearchList(type: string, id = '') {
    if (throttle) await wait(throttle);
    const url = id ? `/savedSearch?repoGuid=${id}&searchType=${type}` : `/savedSearch?searchType=${type}`;
    const res = await ApiHelper.get(url);
    return res.data;
  }

  async saveSearchList(payload) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.post('/savedSearch', payload);
    return res.data;
  }

  async applySearch(payload, cancelToken) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.post('/catalogs/search/advance', payload, cancelToken);
    return res.data;
  }

  async deleteSearchView(id) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.deleteRec(`/savedSearch/${id}`);
    return res.data;
  }

  async getSavedSearchDetail(id: string) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get(`/savedSearch/${id}`);
    return res.data;
  }

  async updateSearchDetail(id, payload) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.put(`/savedSearch/${id}`, payload);
    return res.data;
  }

  async getMetadataFields(repoGuid, sort) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get(`/catalogs/metadata/searchFields?repoGuid=${repoGuid}&sortOrder=${sort}`);
    return res.data;
  }

  async getParentTemplates(catalogId) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get(`/catalogs/metadata/userDefined?catalogId=${catalogId}`);
    return res.data;
  }

  async applyMetadata(payload) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.put('/catalogs/metadata/template', payload);
    return res.data;
  }

  async applyCalculatedFields(payload) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.put('/catalogs/calculatedFields/template', payload);
    return res.data;
  }

  async updateMetadata(catalogId, payload) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.put(`/catalogs/metadata/${catalogId}`, payload);
    return res.data;
  }

  async calculateResult(catalogId, payload) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.post('/catalog/calculatedFields', payload);
    return res.data;
  }

  async getAppliedMetadata(repoGuid, browsePath) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get(`/catalogs/metadata/browsed?repoGuid=${repoGuid}&browsePath=${browsePath}`);
    return res.data;
  }

  async deleteAppliedMetadata(payload) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.deleteRec('/catalogs/metadata', payload);
    return res.data;
  }

  async saveBookmark(payload) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.post('/bookmark', payload);
    return res.data;
  }

  async getBookmarkList(repoGuid: string) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get(`/bookmark/list/${repoGuid}`);
    return res.data;
  }

  async deleteBookmark(id?: string) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.deleteRec(`/bookmark/${id}`);
    return res.data;
  }

  async deleteCatalog(payload: object): Promise<ProjectDetailed> {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.post('/catalogs/browse/markFiles', payload);
    return res.data;
  }

  async applyFlag(analysisType:string, payload: {}) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.put(`/${analysisType}Analysis/flag`, payload);
    return res.data;
  }

  async exportReport(payload: {}) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.post('/reports', payload);
    return res.data;
  }

  async getReports(payload) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.post('/queuedTasks', payload);
    return res.data;
  }

  async getLoadAutoVerify(clientGuid: string, deviceId: string) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get(`/wildfly/clientTapeDevice/loadInfoAutoVerifySchedule?clientGuid=${clientGuid}&deviceId=${deviceId}`);
    return res.data;
  }

  async scheduleAutoVerify(payload) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.post('/wildfly/clientTapeDevice/scheduleAutoVerify', payload);
    return res.data;
  }

  async unscheduleAutoVerify(payload) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.post('/wildfly/clientTapeDevice/unscheduleAutoVerify', payload);
    return res.data;
  }

  async applyFlagCatalog(payload: {}) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.put('/catalogs/flag', payload);
    return res.data;
  }

  async getSizeAndCountOfFolder(repoGuid: string, browsePath: string) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get(`/catalogs/directoryDetails?repoGuid=${repoGuid}&browsePath=${browsePath}`);
    return res.data;
  }

  async searchFilesFolders(payload: {}, cancelToken) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.post('/catalogs/globalSearch', payload, cancelToken);
    return res.data;
  }

  async getGlobalPreviewData(payload) {
    if (throttle) await (wait(throttle));
    const res = await ApiHelper.post('/catalogs/globalSearch/preview', payload);
    return res.data;
  }

  async postFlaggedUnflaggedCount(payload) {
    if (throttle) await (wait(throttle));
    const res = await ApiHelper.post('/catalog/flaggedUnflaggedCount', payload);
    return res.data;
  }

  async deleteReportData(params) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.deleteRec('/reports', params);
    return res.data;
  }

  async getTagList() {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get('/jobTags');
    return res.data;
  }

  async createTag(tag) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.post('/jobTags', { tag });
    return res.data;
  }

  async updateTag(id, payload) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.put(`/jobTags/${id}`, payload);
    return res.data;
  }

  async deleteTag(tags) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.deleteRec('/jobTags', tags);
    return res.data;
  }

  async applyTag(payload) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.post('/jobTags/apply', payload);
    return res.data;
  }

  async getTaggedProjects(payload) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.post('/jobTags/filterJobs', payload);
    return res.data;
  }

  async setGlobalAccess(payload) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.put('/configAccess/global', payload);
    return res.data;
  }

  async getGlobalAccess() {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get('/configAccess/global');
    return res.data;
  }

  async getAccessList() {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get('/configAccess');
    return res.data;
  }

  async addAccess(payload) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.post('/configAccess', payload);
    return res.data;
  }

  async updateAccess(id, payload) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.put(`/configAccess/${id}`, payload);
    return res.data;
  }

  async deleteAccess(id) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.deleteRec(`/configAccess/${id}`);
    return res.data;
  }

  async getTapeLogsList(logsFolder) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get(`/wildfly/tapeLogs/${logsFolder}`);
    return res.data;
  }

  async getValidateTapeLogsList(tapeSerial) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get(`/wildfly/tapeLogs/Validate Tape Reports?tapeSerial=${tapeSerial}`);
    return res.data;
  }

  async downloadTapeLog(payload) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.post('/wildfly/tapeLogs', payload);
    return res.data;
  }

  async removeTapeLogs(payload) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.deleteRec('/wildfly/tapeLogs/remove', payload);
    return res.data;
  }

  async removeAllTapeLogs(logsFolder) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.deleteRec(`/wildfly/tapeLogs/clear/${logsFolder}`);
    return res.data;
  }

  async getSystemSetting(settingName: string) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get(`/systemSettings?settingName=${settingName}`);
    return res.data;
  }

  async addSystemSetting(payload) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.post('/systemSettings', payload);
    return res.data;
  }

  async updateSystemSetting(configName: string, payload) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.put(`/systemSettings/${configName}`, payload);
    return res.data;
  }

  async checkApplicableCalculated(payload) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.post('/catalog/calculatedFields/applicable', payload);
    return res.data;
  }

  async getNotifications(payload) {
    if (throttle) await wait(throttle);
    let url = '/notifications';
    Object.entries(payload).forEach(([key, value], index) => {
      url = index === 0
        ? `${url}?${key}=${value}`
        : `${url}&${key}=${value}`;
    });
    const res = await ApiHelper.get(url);
    return res.data;
  }

  async getFilteredNotifications(payload) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.post('/notifications/filter', payload);
    return res.data;
  }

  async getGlobalSearch(searchTerms, cancelToken) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get(`/catalogs/globalSearchNew?searchTerms=${searchTerms}`, cancelToken);
    return res.data;
  }

  async deleteNotification(params) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.deleteRec('/notifications', params);
    return res.data;
  }

  async checkJobsAvailability(id) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.get(`/jobs/jobAvailability/${id}`);
    return res.data;
  }

  async postGlobalSearch(payload, cancelToken) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.post('/wildfly/globalSearch', payload, cancelToken);
    return res.data;
  }

  async generateReport(payload) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.post('/catalogReports', payload);
    return res?.data ? res?.data : {};
  }

  async getReportsList(payload, cancelToken) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.post('/catalogReports/show', payload, cancelToken);
    return res.data;
  }

  async deleteReport(id) {
    if (throttle) await wait(throttle);
    const res = await ApiHelper.deleteApi(`/catalogReports/delete/${id}`);
    return res?.data ? res?.data : {};
  }
}

export const projectApi = new ProjectApi();
