/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-nested-ternary */
import { ButtonGroup, Button, Tooltip, ButtonProps } from '@material-ui/core';
import { GetJsonIcon } from 'src/icons/get-json';

interface ISdButtonGroupProps extends ButtonProps {
  disabled?: boolean,
  handleButtonClick?: () => void,
  handleJsonClick?: () => void,
  label?: string,
  variant?: 'text' | 'contained' | 'outlined',
  size?: 'small' | 'medium' | 'large',
  color?: any
}

export const SdButtonGroup = (props: ISdButtonGroupProps) => {
  const { disabled, handleJsonClick, label, variant = 'contained', size = 'medium', color, ...restProps } = props;

  return (
    <>
      <ButtonGroup variant="contained" aria-label="Basic button group">
        <Button
          variant={variant}
          size={size}
          disabled={disabled}
          color={color}
          style={{ borderRight: 'none', borderColor: disabled && 'rgba(126, 127, 134, 0.80)' }}
          {...restProps}
        >
          {label}
        </Button>
        <Button
          variant={variant === 'outlined' ? 'contained' : 'outlined'}
          color={color}
          size={size} disabled={disabled} onClick={handleJsonClick}
          sx={{
            // borderLeft: 'none !important',
            borderTopLeftRadius: '0px',
            borderBottomLeftRadius: '0px',
            borderColor: color === 'error' ? '#F44336' : color === 'success' ? '#27AB6E' : '#7B61FF'
          }}
        >
          <Tooltip title="Show JSON">
            <GetJsonIcon sx={{ fontSize: '22px', 'svg path': { fill: disabled && 'grey' } }} />
          </Tooltip>
        </Button>

      </ButtonGroup>
    </>
  );
};
