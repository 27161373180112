import { AxiosRequestConfig, CancelToken } from 'axios';
import SdAxios from 'src/shared/configs/sd-axios';

const multipartConfig = {
  headers: {
    'Content-Type': 'multipart/form-data'
  }
};

const get = async (url: string, config?: AxiosRequestConfig<any>, cancelToken?: CancelToken): Promise<any> => SdAxios.get(url, config, cancelToken);

const post = async (url: string, payload: any = {}, cancelToken?: CancelToken): Promise<any> => SdAxios.post(url, payload, cancelToken);

const patch = async (url: string, payload: any = {}): Promise<any> => SdAxios.patch(url, payload);

const put = async (url: string, payload: any = {}, config: any = {}): Promise<any> => SdAxios.put(url, payload, config);

const deleteRec = async (url: string, payload: any = {}): Promise<any> => SdAxios.delete(url, { data: payload });

const deleteApi = async (url: string): Promise<any> => SdAxios.delete(url);

const upload = async (url: string, payload: any = {}): Promise<any> => SdAxios.post(url, payload, multipartConfig);

export const ApiHelper = { get, post, patch, put, deleteRec, upload, deleteApi };
