import type { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { Container, useMediaQuery } from '@material-ui/core';
import type { Theme } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import ErrorBoundary from 'src/components/error-boundary';
import { Footer } from '../components/footer';
import { DashboardSidebar } from '../components/dashboard-sidebar';
import { useSettings } from '../contexts/settings-context';

const DashboardLayoutRoot = styled('div')(
  ({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    height: '100%',
    paddingTop: 0
  })
);

const OutletContainer = styled('div')(
  () => ({
    flex: 1,
    width: '100%'
  })
);

const DashboardLayoutContent = styled('div')(
  () => ({
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  })
);

export const DashboardContainer = styled(Container)(
  ({ theme }) => ({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    '@media (min-width: 1440px)': {
      paddingRight: theme.spacing(10),
      paddingLeft: theme.spacing(10),
      maxWidth: '2000px'
    }
  })
);

export const DashboardLayout: FC = () => {
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const { settings, saveSettings } = useSettings();

  const handlePinSidebar = (): void => {
    saveSettings({
      ...settings,
      pinSidebar: !settings.pinSidebar
    });
  };

  return (
    <DashboardLayoutRoot>
      {!mdDown && (
        <DashboardSidebar
          onPin={handlePinSidebar}
          pinned={settings.pinSidebar}
          disabled={settings.disabled}
        />
      )}
      <DashboardLayoutContent
        sx={{
          backgroundColor: 'background.default',
          ml: {
            md: settings.pinSidebar ? '270px' : '73px'
          }
        }}
      >
        <DashboardContainer
          maxWidth="xl"
        >
          <OutletContainer>
            <ErrorBoundary>
              <Outlet />
            </ErrorBoundary>
          </OutletContainer>
          <Footer />
        </DashboardContainer>
      </DashboardLayoutContent>
    </DashboardLayoutRoot>
  );
};
