import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Box, Button } from '@material-ui/core';
import { useAuth } from '../../hooks/use-auth';
import { useMounted } from '../../hooks/use-mounted';

interface LoginAuth0Props {
  setError: (errorMsg: string) => void;
}
export const LoginAuth0: FC<LoginAuth0Props> = (props) => {
  const { setError } = props;
  const mounted = useMounted();
  const { loginWithPopup } = useAuth() as any;
  // const [error, setError] = useState<string | null>(null);

  const handleLogin = async (): Promise<void> => {
    try {
      await loginWithPopup();
    } catch (err) {
      console.error(err);
      if (mounted.current) {
        setError(err?.response?.data?.result ? err.response.data.result : err?.message);
      }
    }
  };

  return (
    <div {...props}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <Button
          color="primary"
          onClick={handleLogin}
          variant="contained"
        >
          Log In
        </Button>
      </Box>
    </div>
  );
};

LoginAuth0.propTypes = {
  setError: PropTypes.func.isRequired
};
