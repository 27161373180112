/* eslint-disable react/state-in-constructor */
import { Box, Button, Link, styled, Typography } from '@material-ui/core';
import { Component, ErrorInfo } from 'react';

interface IState {
  hasError: boolean;
  errorInfo: ErrorInfo;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IProps {
  children: any;
}

const Wrraper = styled(Box)(() => ({
  margin: 'auto',
  maxWidth: '900px',
  width: '100%',
  height: '100%',
  marginTop: '50px',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  alignItems: 'flex-start'
}));

export default class ErrorBoundary extends Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = { errorInfo: { componentStack: '' },
      hasError: false, };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ errorInfo });
  }

  render() {
    const { children } = this.props;
    const { hasError, errorInfo, } = this.state;
    if (hasError) {
      return (
        <Wrraper>
          <Typography variant="h2" textAlign="center"> Whoops, Something went wrong</Typography>
          <Typography variant="h5" textAlign="center">Please either refresh the page or return home to try again.</Typography>
          <Box display="flex" gap="30px" alignItems="center">

            <Link href="/">Go to Dashboard</Link>
            <Button
              onClick={() => {
                window.location.reload();
              }}
            >
              Reload this page
            </Button>
          </Box>
          <Box>
            <details className="error-details">
              <summary>Click for error details</summary>
              {errorInfo && errorInfo?.componentStack?.toString()}
            </details>
          </Box>
        </Wrraper>
      );
    }
    return children;
  }
}
