import type { FC } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@mui/icons-material/Close';
import { SdButtonGroup } from 'src/shared/components/sd-json-button-group';

interface ConfirmationDialogProps {
  message: string;
  onCancel?: () => void;
  onConfirm?: () => void;
  open: boolean;
  cancelText?: string;
  confirmText?: string;
  title?: string;
  cancelBtnColor?: any;
  cancelVariant?: any;
  confirmBtncolor?: any;
  confirmVariant?: any;
  variant?: 'error' | 'warning' | 'info';
  extensionField?: Element | React.ReactNode;
  disabled?: boolean;
  multiLineMessage?: boolean;
  TransitionComponent?: any;
  keepMounted?: any;
  maxWidth?:any;
  isCancelIcon?: boolean;
  disableEscapeKeyDown?: boolean;
  size?:string;
  additionalBtnText?: string;
  additionalBtnColor?: any;
  additionalBtnVariant?: any;
  handleAdditionalBtn?: () => void;
}

const icons = {
  error: <ErrorIcon color="error" fontSize="large" />,
  warning: <WarningIcon color="warning" fontSize="large" />,
  info: <InfoIcon color="info" fontSize="large" />,
};

export const ConfirmationDialog: FC<ConfirmationDialogProps> = (props) => {
  const {
    message,
    onCancel,
    onConfirm,
    open,
    title,
    variant,
    cancelText,
    confirmText,
    cancelBtnColor,
    cancelVariant,
    confirmBtncolor,
    confirmVariant,
    extensionField,
    disabled = false,
    multiLineMessage = false,
    TransitionComponent,
    maxWidth,
    isCancelIcon = true,
    disableEscapeKeyDown = false,
    size = '100%',
    additionalBtnText,
    additionalBtnVariant,
    additionalBtnColor,
    handleAdditionalBtn,
    ...other
  } = props;

  const customizedMessage = message.split('\\n');
  return (
    <Dialog
      disableEscapeKeyDown={disableEscapeKeyDown}
      maxWidth={maxWidth}
      onClose={(e) => { console.log(e); onCancel(); }}
      open={open}
      TransitionComponent={TransitionComponent}
      PaperProps={{
        sx: {
          width: size,
        },
      }}
      {...other}
    >
      {title && (
        <DialogTitle
          id="alert-dialog-slide-title"
          sx={{
            alignItems: 'center',
            display: 'flex',
          }}
        >
          {icons[variant]}
          <Typography color="inherit" sx={variant && { ml: 2 }} variant="inherit">
            {title}
          </Typography>
        </DialogTitle>
      )}
      {
        isCancelIcon ? (
          <IconButton
            aria-label="close"
            onClick={onCancel}
            sx={{
              position: 'absolute',
              right: 0,
              top: 0,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : ''
      }
      <DialogContent
        id="alert-dialog-slide-description"
        sx={{ mb: !confirmText && variant ? '0px' : '0px', pt: title ? '0px' : '24px !important' }}
      >
        {customizedMessage?.map((item, index) => (
          <Typography
            key={`customized-message-${index.toString()}`}
            color="textPrimary"
            variant={title ? 'body1' : 'body2'}
            // eslint-disable-next-line no-nested-ternary
            sx={{ mt: index > 0 ? (multiLineMessage ? 0 : 3) : 0 }}
          >
            {item}
          </Typography>
        ))}
        {extensionField}
      </DialogContent>
      {(cancelText || confirmText) && (
        <DialogActions>
          {cancelText && (
            <Button
              data-testid="cancelButton"
              color={cancelBtnColor}
              onClick={onCancel}
              variant={cancelVariant}
            >
              {cancelText}
            </Button>
          )}
          {additionalBtnText && (
          <SdButtonGroup
            type="submit"
            data-testid="confirmButton"
            color={confirmBtncolor}
            onClick={onConfirm}
            variant={confirmVariant}
            disabled={disabled}
            form="add-edit-file-system-form"
            label={confirmText}
            handleJsonClick={handleAdditionalBtn}
          />
          )}
          {confirmText && !additionalBtnText?.length && (
            <Button
              type="submit"
              data-testid="confirmButton"
              color={confirmBtncolor}
              onClick={onConfirm}
              variant={confirmVariant}
              disabled={disabled}
              form="add-edit-file-system-form"
            >
              {confirmText}
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

ConfirmationDialog.defaultProps = {
  open: false,
};

ConfirmationDialog.propTypes = {
  message: PropTypes.string.isRequired,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string,
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
  cancelBtnColor: PropTypes.string,
  cancelVariant: PropTypes.string,
  confirmBtncolor: PropTypes.string,
  confirmVariant: PropTypes.string,
  variant: PropTypes.oneOf(['error', 'warning', 'info']),
  extensionField: PropTypes.object,
  disabled: PropTypes.bool,
  multiLineMessage: PropTypes.bool,
  TransitionComponent: PropTypes.any,
  keepMounted: PropTypes.any,
  maxWidth: PropTypes.any,
  isCancelIcon: PropTypes.any,
  disableEscapeKeyDown: PropTypes.bool,
  size: PropTypes.string,
  additionalBtnText: PropTypes.string,
  additionalBtnColor: PropTypes.string,
  additionalBtnVariant: PropTypes.string,
  handleAdditionalBtn: PropTypes.func,
};
