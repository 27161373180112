/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import type { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { Alert, Box, Card, Grid, CardContent, Container, Typography, } from '@material-ui/core';
import { projectApi } from 'src/api/project';
import ImageSlideshow from 'src/components/settings/slideshow';
import { Button, Divider, Skeleton } from '@mui/material';
import { useOktaAuth } from '@okta/okta-react';
import { oktaConfig } from 'src/oktaConfig';
import { OktaSmallIcon } from 'src/icons/okta-small-icon';
import { LoginAmplify } from '../components/auth/login-amplify';
import { LoginAuth0 } from '../components/auth/login-auth0';
import { LoginFirebase } from '../components/auth/login-firebase';
import { LoginJwt } from '../components/auth/login-jwt';
import { useAuth } from '../hooks/use-auth';
import gtm from '../lib/gtm';

export const Login: FC = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const { method } = useAuth() as any;
  const { loginWithOkta } = useAuth() as any;
  const [error, setError] = useState(null);
  const [images, setImages] = useState([]);
  const [logo, setLogo] = useState<any>('');
  const [isLoading, setIsLoading] = useState(false);
  const [isOktaEnabled, setIsOktaEnabled] = useState((localStorage.getItem('isOktaEnabled') === 'true') || false);

  const defaultImagesFromPublic = ['/static/login-screen-splash-banner-image-1.png', '/static/login-screen-splash-banner-image-2.png', '/static/login-screen-splash-banner-image-3.png'];
  const defaultLogoFromPublic = ['/static/login-screen-splash-banner-logo.png'];

  const getOktaUserDetail = async () => {
    try {
      const res = await oktaAuth.getUser();
      // console.log('Res from okta : ', res);
      // console.log(authState.accessToken);
    } catch (err) {
      console.log('Error : ', err);
    }
  };

  useEffect(() => {
    if (authState?.isAuthenticated) {
      getOktaUserDetail();
    }
  }, [authState, oktaAuth]);

  async function fetchBlobFromUrl(url) {
    const response = await fetch(url);
    const blob = await response.blob();
    return blob;
  }

  async function convertPathsToBlobFiles(paths: string[]): Promise<File[]> {
    const blobPromises = paths.map(fetchBlobFromUrl);
    const blobs = await Promise.all(blobPromises);

    // Create File objects from Blob objects
    const files = blobs.map((blob: Blob, index: number) => new File([blob], `file${index}.png`));

    return files;
  }

  const getLoginPersonalize = async () => {
    setIsLoading(true);
    try {
      const res = await projectApi.getLoginPersonalizeLoginScreen();
      if (res?.result) {
        setIsLoading(false);
        setImages(res?.result?.backgroundImages?.selected);
        setLogo(res?.result?.logo?.selected);
      } else {
        setIsLoading(false);

        setImages([defaultImagesFromPublic[0]]);
        setLogo(defaultLogoFromPublic[0]);

        convertPathsToBlobFiles(defaultImagesFromPublic)
          .then((files) => {
            setImages([files[0]]);
          })
          .catch((err) => {
            console.error('Error converting paths to files:', err);
          });

        convertPathsToBlobFiles(defaultLogoFromPublic)
          .then((files) => {
            setLogo(files[0]);
          })
          .catch((err) => {
            console.error('Error converting paths to files:', err);
          });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getOktaConfig = async () => {
    try {
      const res = await projectApi.getSystemSetting('oktaConfig');
      if (res?.isEnabled) {
        setIsOktaEnabled(res.isEnabled);
        oktaConfig.clientId = res.settings.clientId;
        oktaConfig.issuer = res.settings.issuerURI;
        oktaConfig.redirectUri = res.settings.redirectURI;
      }
    } catch (err) {
      console.log('Error : ', err);
    }
  };
  useEffect(() => {
    gtm.push({ event: 'page_view' });
    getLoginPersonalize();
    getOktaConfig();
  }, []);

  return (
    <>
      <Helmet>
        <title>Login | DNAfabric</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Box>
          <Grid
            container
          >
            <Grid
              sx={{
                width: '36.45%',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                display: 'flex',
                height: '100vh',
                justifyContent: 'center'
              }}
            // style={{ backgroundImage: 'url(static/login-screen-splash-banner-image-1.png)' }}
            // item
            >
              {
                isLoading ? (
                  <Box sx={{ width: 300, alignSelf: 'center' }}>
                    <Skeleton />
                    <Skeleton animation="wave" />
                    <Skeleton animation={false} />
                  </Box>
                ) : (
                  <ImageSlideshow images={images} logo={logo} interval={3000} source="loginPage" />
                )
              }
              {/* <Box
                component="img"
                sx={{
                  marginTop: '160px',
                  height: 92,
                  width: 400,
                }}
                alt="The house from the offer."
                src="/static/login-screen-splash-banner-logo.png"
              /> */}
            </Grid>
            <Grid
              width="63.55%"
              height="100vh"
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="start"
            >
              <Box margin="10px" height="318px" width="100%" padding="0px 24px" display="flex" alignItems="center" justifyContent="center">
                <Card
                  sx={{ backgroundColor: 'background.default', paddingBottom: '20px', borderBottom: '1px solid gray', width: '100%', borderRadius: '0px' }}
                  elevation={0}
                >
                  <Typography
                    color="primary"
                    textAlign="center"
                    fontSize="48px"
                    fontWeight="600"
                    sx={{ backgroundColor: 'background.default' }}
                  >
                    DNAfabric: The Data Unification Platform
                  </Typography>
                  <Typography
                    textAlign="center"
                    fontSize="32px"
                    fontWeight="600"
                    sx={{ backgroundColor: 'background.default' }}
                  >
                    Cloud Optimized Distributed Data Management Platform
                  </Typography>
                </Card>
              </Box>
              <Container sx={{ width: '63.55%', display: 'flex', justifyContent: 'center' }} maxWidth="sm">
                <Box width="402px" border="1px solid gray" borderRadius="10px">
                  <Card
                    sx={{ backgroundColor: 'background.default', borderRadius: '20px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
                    elevation={0}
                  >
                    <CardContent>
                      {method === 'Amplify' && <LoginAmplify setError={setError} />}
                      {method === 'Auth0' && <LoginAuth0 setError={setError} />}
                      {method === 'Firebase' && <LoginFirebase setError={setError} />}
                      {method === 'JWT' && <LoginJwt setError={setError} />}
                      {
                        error
                        && (
                          <Alert severity="error" sx={{ mt: 2 }}>
                            {error}
                          </Alert>
                        )
                      }
                    </CardContent>

                    {isOktaEnabled
                      && (
                        <Box sx={{ margin: '0px 20px 20px 20px', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                          <Box sx={{ marginBottom: '36px' }}>
                            <Divider>OR</Divider>
                          </Box>
                          <Button
                            sx={{ width: '200px', margin: 'auto auto' }}
                            color="primary"
                            startIcon={<OktaSmallIcon />}
                            // fullWidth
                            size="large"
                            type="submit"
                            variant="outlined"
                            onClick={async () => {
                              await oktaAuth.signInWithRedirect();
                            }}
                          >
                            Login with Okta
                          </Button>
                        </Box>
                      )}
                  </Card>
                </Box>
              </Container>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};
