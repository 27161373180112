import React, { useMemo, useState } from 'react';
import { Snackbar, SnackbarContent, Button, IconButton, Box } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useToast = ({ ...props }: { [key: string]: any } = {}) => {
  const [toastHandling, setToastHandling] = useState<any>({
    status: false,
    message: '',
    duration: 3000,
    note: ''
  });
  const [handleToast, setHandleToast] = useState(false);
  const dontShowData = localStorage.getItem('dontShowToast') ? JSON.parse(localStorage.getItem('dontShowToast')) : [];

  const handleToastClose = () => {
    setHandleToast(false);
  };

  // Implement logic for "Don't show" button action
  const handleDontShow = () => {
    handleToastClose();
    dontShowData?.push(toastHandling?.parent);
    localStorage.setItem('dontShowToast', JSON.stringify(dontShowData));
  };

  const handleOpen = (data) => {
    setHandleToast(true);
    setToastHandling(data);
  };

  const SnackBarComp = useMemo(() => (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={handleToast}
      onClose={() => { if (toastHandling?.autoHide !== 'no') { handleToastClose(); } }}
      autoHideDuration={toastHandling?.autoHide !== 'no' ? toastHandling.duration || 3000 : null}
      style={{ zIndex: 99999 }}
      {...props}
    >
      <SnackbarContent
        style={{
          backgroundColor: (toastHandling?.status ? '#27AB6E' : !toastHandling?.status && '#EC4C47'),
          color: 'white',
          zIndex: 99999,
          display: 'flex',
          flexDirection: (toastHandling?.autoHide === 'no') && 'column',
          alignItems: 'center',
        }}
        {...props.ContentStyle}
        message={(
          <Box>
            <span>{toastHandling?.message}</span>
            {toastHandling?.note?.length > 0 ? (
              <>
                <br />
                {toastHandling.note}
              </>
            ) : ''}
            {toastHandling?.autoHide === 'no' && (
            <IconButton key="close" color="inherit" onClick={handleToastClose}>
              <CloseIcon />
            </IconButton>
            )}
          </Box>
        )}
        action={[
          toastHandling?.autoHide === 'no' && (
          <Button color="secondary" key="dontShow" size="small" onClick={handleDontShow} style={{ marginBottom: '5px' }}>
            Don&apos;t Show Again
          </Button>
          ),
        ]}
      />
    </Snackbar>
  ), [handleToast, handleToastClose, toastHandling, props]);

  return {
    Toast: () => SnackBarComp,
    handleOpen,
    handleToastClose,
  };
};

export default useToast;
