import { Status } from 'src/components/status';

export const formateDateTime = (time = '') => {
  if (time) return time.replace('T', ' ').replace('.000Z', '') || '';
  return time;
};

export const formateSize = (bits: number): string => {
  if (!bits) return '0';
  let res = bits;
  const units = ['B', 'KB', 'MB', 'GB', 'TB'];
  let unitIndex = 0;
  while (unitIndex <= units.length - 1 && res >= 1024) {
    res /= 1024;
    ++unitIndex;
  }
  const numFixed = Number(res)?.toFixed(2);
  const numDisplay = numFixed === '0.00' ? '0' : numFixed;
  return `${numDisplay} ${units[unitIndex]}`;
  // return `${numDisplay} Mbits/s`;
};

export const formatBandwidth = (bits: number): string => {
  if (!bits && bits !== 0) {
    return 'n/a';
  }
  let res = bits;
  const units = ['bits', 'Kbits', 'Mbits', 'Gbits', 'Tbits'];
  let unitIndex = 0;
  while (unitIndex <= units.length - 1 && res >= 1024) {
    res /= 1024;
    ++unitIndex;
  }

  const numFixed = res.toFixed(2);
  const numDisplay = numFixed === '0.00' ? '0' : numFixed;
  return `${numDisplay} ${units[unitIndex]}/s`;
};

export const formateDuration = (date_: Date) => {
  const ms = (new Date()).getTime() - (new Date(date_)).getTime();
  const time = {
    day: Math.floor(ms / 86400000),
    hour: Math.floor(ms / 3600000) % 24,
    minute: Math.floor(ms / 60000) % 60,
    second: Math.floor(ms / 1000) % 60,
    millisecond: Math.floor(ms) % 1000
  };

  return Object.entries(time)
    .filter((val) => val[1] !== 0)
    .map(([key, val]) => `${val} ${key}${val !== 1 ? 's' : ''}`)[0];
};

export const formateDurationMs = (ms : number) => {
  const time = {
    day: Math.floor(ms / 86400000),
    hour: Math.floor(ms / 3600000) % 24,
    minute: Math.floor(ms / 60000) % 60,
    second: Math.floor(ms / 1000) % 60,
    ms: Math.floor(ms) % 1000
  };

  return Object.entries(time)
    .filter((val) => val[1] !== 0)
    .map(([key, val]) => `${val} ${key}`)[0];
};

export const formatLastRun = (date_: any): any => {
  if (!date_ || date_ === '-') return 0;
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const date = new Date(date_); // idk why I have to do this
  const now = new Date();
  const secDiff = Math.floor(now.getTime() - date.getTime()) / 1000;
  if (secDiff <= 10) {
    return 'just now';
  } if (secDiff < 60) {
    return `${(now.getSeconds() - date.getSeconds() + 60) % 60}s`;
  } if (secDiff < 60 * 60) {
    return `${(now.getMinutes() - date.getMinutes() + 60) % 60}m`;
  } if (secDiff < 60 * 60 * 24) {
    return `${(now.getHours() - date.getHours() + 24) % 24}h`;
  } if (date.getFullYear() === now.getFullYear()) {
    return `${months[date.getMonth()]} ${date.getDate()}`;
  }

  const formattedDate: string = date.toLocaleString('en-GB', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit'
  });

  return formattedDate;
};

const descendingComparator = (a: any, b: any, sortBy: string): number => {
  if (b[sortBy] < a[sortBy]) {
    return -1;
  }

  if (b[sortBy] > a[sortBy]) {
    return 1;
  }

  return 0;
};

export const applySort = (rows: Array<any>, sort: string, sortBy: string): Array<any> => {
  if (!rows || !sort) {
    return rows;
  }

  return rows.sort((a, b) => (
    sort === 'desc'
      ? descendingComparator(a, b, sortBy)
      : -descendingComparator(a, b, sortBy)
  ));
};

export const formatStatus = (status: string, option = true) => {
  const lower = status.toLowerCase();
  let color: string;
  if (lower.startsWith('never started')) {
    color = '#90CAF9';
  } else if (['failed', 'fail', 'error preparing'].includes(lower)) {
    color = 'error.main';
  } else if (['warning', 'read only'].includes(lower)) {
    color = 'warning.main';
  } else if (lower === 'processing') {
    color = 'warning.main';
  } else if (['success', 'enabled', 'ready', 'pass', 'completed', 'read & write'].includes(lower)) {
    color = 'success.main';
  } else if (lower.startsWith('disabled')) {
    color = 'text.disabled';
  } else {
    color = 'primary.main';
  }
  if (!option) {
    color = 'text.disabled';
  }

  return (<Status color={color} label={status} />);
};

export const convertToBytes = (bytes: string) => {
  const format = bytes?.split(' ');
  if (format?.includes('GB')) {
    return +format[0] * 1073741824;
  }
  if (format?.includes('MB')) {
    return +format[0] * 1048576;
  }
  if (format?.includes('KB')) {
    return +format[0] * 1024;
  }
  return +format[0];
};
