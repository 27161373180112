/* eslint-disable consistent-return */
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Button, Grid, IconButton, InputAdornment, Typography } from '@material-ui/core';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { projectApi } from 'src/api/project';
// import { socket } from 'src/api/auth';
import { InputField } from '../input-field';
import { useAuth } from '../../hooks/use-auth';
import { useMounted } from '../../hooks/use-mounted';

interface LoginJwtProps {
  setError: (errorMsg: string) => void;
}
export const LoginJwt: FC<LoginJwtProps> = (props) => {
  const { setError } = props;
  const [isShowPassword, setIsShowPassword] = useState(false);

  const mounted = useMounted();
  const { login } = useAuth() as any;

  const postCloudCostUrl = async () => {
    const payload = {
      settingName: 'cloudCost',
      settings: {
        baseURL: 'https://public.tableau.com/app/profile/storagedna3255/viz/CloudCostAnalysis-DNAfabric/Grid',
      }
    };

    try {
      await projectApi.addSystemSetting(payload);
    } catch (error) {
      console.error(error);
    }
  };

  const postFrameIoUrl = async () => {
    const payload = {
      settingName: 'frame.io',
      settings: {
        baseURL: 'https://app.frame.io/player/',
      }
    };

    try {
      await projectApi.addSystemSetting(payload);
    } catch (error) {
      console.error(error);
    }
  };

  const getCloudCostURL = async () => {
    try {
      const res = await projectApi.getSystemSetting('cloudCost');
      if (!res?.systemSettings) {
        postCloudCostUrl();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getFrameIoURL = async () => {
    try {
      const res = await projectApi.getSystemSetting('frame.io');
      if (!res?.systemSettings) {
        postFrameIoUrl();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      submit: null,
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().max(255).required('Username is required'),
      password: Yup.string().max(255).required('Password is required'),
      policy: Yup.boolean(),
    }),
    onSubmit: async (values, helpers) => {
      try {
        await login(values.email, values.password);
        if (mounted.current) {
          helpers.setStatus({ success: true });
          helpers.setSubmitting(false);
          getCloudCostURL();
          getFrameIoURL();

          // socket.connect();

          // socket.emit('login', { email: values?.email });

          // return () => {
          //   socket.disconnect();
          // };
        }
      } catch (err) {
        if (mounted.current) {
          helpers.setStatus({ success: false });
          helpers.setErrors({ submit: err?.response?.data?.result });
          helpers.setSubmitting(false);
          setError(err?.response?.data?.result ? err.response.data.result : err?.message);
        }
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          mb: 3,
        }}
      >
        <Typography color="textPrimary" variant="h4">
          Login
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        {/* <Button
          color="primary"
          component={BrowserLink}
          to="/register"
          variant="text"
        >
          Sign Up
        </Button> */}
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <InputField
            autoFocus
            error={Boolean(formik.touched.email && formik.errors.email)}
            fullWidth
            helperText={formik.touched.email && formik.errors.email}
            label="Username"
            name="email"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            // type="email"
            value={formik.values.email}
          />
        </Grid>
        <Grid item xs={12}>
          <InputField
            error={Boolean(formik.touched.password && formik.errors.password)}
            fullWidth
            helperText={formik.touched.password && formik.errors.password}
            label="Password"
            name="password"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.password}
            type={isShowPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setIsShowPassword(!isShowPassword)}>
                    {isShowPassword ? (
                      <VisibilityIcon color="disabled" />
                    ) : (
                      <VisibilityOffIcon color="disabled" />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            color="primary"
            disabled={formik.isSubmitting}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
          >
            Login
          </Button>
        </Grid>
        <Grid item xs={12}>
          {/* <Button
            color="primary"
            component={BrowserLink}
            to="/password-recovery"
            variant="text"
          >
            Forgot password
          </Button> */}
        </Grid>
      </Grid>
    </form>
  );
};

LoginJwt.propTypes = {
  setError: PropTypes.func.isRequired,
};
