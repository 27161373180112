import type { FC } from 'react';
import { Box } from '@material-ui/core';

// const links = [
//   {
//     label: 'About Us',
//     href: 'https://devias.io/about-us'
//   },
//   {
//     label: 'Terms',
//     href: 'https://devias.io/legal/tos'
//   }
// ];

export const Footer: FC = () => (
  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: { sm: 'row', xs: 'column' }, py: 2, px: 3, width: '100%', }}>
    {/* <Typography color="textSecondary" variant="caption">
      ©
      {' '}
      {new Date().getFullYear() }
      {' '}
      Devias
    </Typography> */}

    {/* <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '40px' }}>
      {links.map((link) => (
        <Link
          color="textSecondary"
          href={link.href}
          key={link.label}
          target="_blank"
          underline="none"
          variant="body2"
          sx={{ p: 0 }}
        >
          {link.label}
        </Link>
      ))}
    </Box> */}
  </Box>
);
