import { createContext, useEffect, useReducer } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import type { User } from '../types/user';
import { authApi } from '../api/auth';
import { useSettings } from './settings-context';

interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: User | null;
  isFreshUser : boolean;
}

interface AuthContextValue extends State {
  method: 'JWT';
  login: (email: string, password: string) => Promise<void>;
  loginWithOkta: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  register: (email: string, name: string, password: string) => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitializeAction = {
  type: 'INITIALIZE';
  payload: {
    isAuthenticated: boolean;
    user: User | null;
    isFreshUser: boolean
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: User;
    isFreshUser: boolean;
  };
};

type LoginWithOktaAction = {
  type: 'LOGINWITHOKTA';
  payload: {
    user: User;
    isFreshUser: boolean;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type RegisterAction = {
  type: 'REGISTER';
  payload: {
    user: User;
  };
};

type Action =
  | InitializeAction
  | LoginAction
  | LoginWithOktaAction
  | LogoutAction
  | RegisterAction;

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  isFreshUser: false,
  user: null
};

const handlers: Record<string, (state: State, action: Action) => State> = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const { isAuthenticated, user, isFreshUser } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      isFreshUser,
      user
    };
  },
  LOGIN: (state: State, action: LoginAction): State => {
    const { user, isFreshUser } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      isFreshUser,
      user
    };
  },
  LOGINWITHOKTA: (state: State, action: LoginWithOktaAction): State => {
    const { user, isFreshUser } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      isFreshUser,
      user
    };
  },
  LOGOUT: (state: State): State => ({
    ...state,
    isAuthenticated: false,
    isFreshUser: false,
    user: null
  }),
  REGISTER: (state: State, action: RegisterAction): State => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      isFreshUser: true,
      user
    };
  }
};

const reducer = (state: State, action: Action): State => (
  handlers[action.type] ? handlers[action.type](state, action) : state
);

export const AuthContext = createContext<AuthContextValue>({
  ...initialState,
  method: 'JWT',
  login: () => Promise.resolve(),
  loginWithOkta: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve()
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const { settings, saveSettings } = useSettings();

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');
        const wildflyToken = window.localStorage.getItem('wildflyToken');

        if (accessToken) {
          const user = await authApi.me(accessToken, wildflyToken);

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              isFreshUser: false,
              user
            }
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              isFreshUser: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            isFreshUser: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (email: string, password: string): Promise<void> => {
    const { accessToken, wildflyToken } = await authApi.login({ email, password });
    const user = await authApi.me(accessToken, wildflyToken);

    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('wildflyToken', wildflyToken);
    localStorage.setItem('userName', user.userName);
    localStorage.setItem('userRole', user.userRole);
    localStorage.setItem('Name', user.name);

    // user.isFreshUser = true;

    saveSettings({ ...settings, disabled: user.isFreshUser, pinSidebar: !user.isFreshUser });
    dispatch({
      type: 'LOGIN',
      payload: {
        isFreshUser: user.isFreshUser || false,
        user
      }
    });
  };

  const loginWithOkta = async (email: string, password: string): Promise<void> => {
    const { accessToken, wildflyToken } = await authApi.login({ email, password });
    const user = await authApi.me(accessToken, wildflyToken);

    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('wildflyToken', wildflyToken);
    localStorage.setItem('userName', user.userName);
    localStorage.setItem('userRole', user.userRole);
    localStorage.setItem('Name', user.name);

    // user.isFreshUser = true;

    saveSettings({ ...settings, disabled: user.isFreshUser, pinSidebar: !user.isFreshUser });
    dispatch({
      type: 'LOGIN',
      payload: {
        isFreshUser: user.isFreshUser || false,
        user
      }
    });
  };

  const prevUser = localStorage.getItem('previousUser');
  const currentUser = localStorage.getItem('userName');

  useEffect(() => {
    if (prevUser && prevUser !== currentUser) {
      localStorage.removeItem('jobType');
      localStorage.removeItem('jobTags');
      localStorage.removeItem('collection');
      localStorage.removeItem('filters');
      localStorage.removeItem('dontShowToast');
      sessionStorage.removeItem('queryAll');
      sessionStorage.removeItem('queryActive');
      sessionStorage.removeItem('queryFailure');
      localStorage.removeItem('dontShowWarning');
      localStorage.removeItem('persistedRetrieveData');
      localStorage.removeItem('clipSearchText');
      localStorage.removeItem('dontShowFlagNewestWarning');
    }
  }, [prevUser, currentUser]);

  const logout = async (): Promise<void> => {
    localStorage.setItem('previousUser', currentUser);
    localStorage.removeItem('accessToken');
    localStorage.removeItem('wildflyToken');
    localStorage.removeItem('userRole');
    localStorage.removeItem('Name');
    localStorage.removeItem('storageSelectedFiles');
    localStorage.removeItem('storageData');
    localStorage.removeItem('clientMachine');
    localStorage.removeItem('trendsMachine');
    localStorage.removeItem('trendsUsers');
    // localStorage.removeItem('selectProjectCollection');
    localStorage.removeItem('browse');
    localStorage.removeItem('displayedPath');
    localStorage.removeItem('guideSize');
    localStorage.removeItem('tapeFilters');
    dispatch({ type: 'LOGOUT' });
  };

  const register = async (
    email: string,
    name: string,
    password: string
  ): Promise<void> => {
    const accessToken = await authApi.register({ email, name, password });
    const user = await authApi.me(accessToken, 'DELETE ME PLEASE');

    localStorage.setItem('accessToken', accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user
      }
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        loginWithOkta,
        logout,
        register
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};
