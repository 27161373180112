export const lightPrimary = {
  contrast: '#ffffff',
  dark: '#38319f',
  light: '#736Be9',
  main: '#536DFE'
};

export const lightSecondary = {
  contrast: '#ffffff',
  dark: '#38319f',
  light: '#736Be9',
  main: '#5046e4'
};

export const lightError = {
  contrast: '#ffffff',
  dark: '#a53531',
  light: '#ef6f6b',
  main: '#ec4c47'
};

export const lightWarning = {
  contrast: '#ffffff',
  dark: '#975b1e',
  light: '#e09b55',
  main: '#d9822b'
};

export const lightInfo = {
  contrast: '#ffffff',
  dark: '#0b4e8d',
  light: '#3f8cd4',
  main: '#1070CA'
};

export const lightSuccess = {
  contrast: '#ffffff',
  dark: '#1b774d',
  light: '#52bb8b',
  main: '#27AB6E'
};

export const lightBackground = {
  default: '#ffffff',
  paper: '#ffffff'
};

export const lightText = {
  primary: '#03060b',
  secondary: '#506176'
};

export const lightNeutral = {
  100: '#f3f4f7',
  200: '#e1e3ea',
  300: '#d5d8e1',
  400: '#b7bdcc',
  500: '#9aa1b7',
  600: '#7c85a2',
  700: '#3f455a',
  800: '#2a2f3c',
  900: '#1e212a'
};

export const darkPrimary = {
  contrast: '#ffffff',
  dark: '#363089',
  light: '#9599ff',
  main: '#7B61FF'
};

export const darkSecondary = {
  contrast: '#ffffff',
  dark: '#506176',
  light: '#9599ff',
  main: '#506176'
};

export const darkError = {
  contrast: '#ffffff',
  dark: '#85372b',
  light: '#F44336',
  main: '#BD3D44'
};

export const darkWarning = {
  contrast: '#ffffff',
  dark: '#8D682d',
  light: '#f7bb57',
  main: '#D9822B'
};

export const darkInfo = {
  contrast: '#ffffff',
  dark: '#1D4580',
  light: '#4483df',
  main: '#1070CA'
};

export const darkSuccess = {
  contrast: '#ffffff',
  dark: '#27AB6E',
  light: '#5fc16d',
  main: '#27AB6E'
};

export const darkDisabled = {
  contrast: 'rgba(126, 127, 134, 0.85);',
  dark: 'rgba(126, 127, 134, 0.25)',
  light: '#5fc16d',
  main: 'rgba(126, 127, 134, 0.25)'
};

export const darkBackground = {
  default: '#1B1B23',
  paper: '#1E212A',
  dark: 'rgba(126, 127, 134, 1)'
};

export const darkText = {
  primary: '#FFFFFF',
  secondary: 'rgba(255, 255, 255, 0.7)',
};

export const darkNeutral = {
  100: '#1e212a',
  200: '#2b2f3c',
  300: '#40444f',
  400: '#545863',
  500: '#6c6f7f',
  600: '#868998',
  700: '#a1a4af',
  800: '#bdbfc7',
  900: '#d8dade'
};
