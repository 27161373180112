import type { FC } from 'react';
import { Box, Typography } from '@material-ui/core';
import { StatusBadge } from './status-badge';

interface StatusProps {
  color: string;
  label: string;
}

export const Status: FC<StatusProps> = (props: StatusProps) => {
  const { color, label, ...other } = props;

  return (
    <Box sx={{ alignItems: 'center', display: 'flex' }} {...other}>
      <StatusBadge color={color} />
      <Typography component="span" sx={{ color, ml: '6px' }} variant="body2">{label}</Typography>
    </Box>
  );
};
