/* eslint-disable no-nested-ternary */
import { Box } from '@material-ui/core';
import React, { useState, useEffect } from 'react';

const ImageSlideshow = ({ images, interval, logo, source = '' } : any) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (images?.length) setCurrentIndex((prevIndex) => (prevIndex + 1) % images?.length);
    }, interval);

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, [currentIndex, images?.length, interval]);

  // const origin = 'http://192.168.3.105';

  return (
    <Box height="100%" width="100%" borderRadius="8px 0px 0px 8px" className="slideshow-container" position="relative">
      <Box width="100%" display="flex" justifyContent="center">
        <img
          src={(logo?.length || logo?.name?.length) ? (typeof logo === 'string' ? `${window.location.origin.concat(logo?.replace(/ /g, '%20'))}` : (logo?.name?.length ? URL?.createObjectURL(logo) : '')) : ''}
          alt={`Logo ${currentIndex + 1}`}
          style={{
            width: source?.length ? '50%' : '55%',
            height: '37%',
            position: 'absolute',
            zIndex: 1,
            borderRadius: '8px 0px 0px 8px',
            objectFit: 'contain',
          }}
        />
      </Box>
      <img
        // src={images?.length > 0 ? (typeof images[currentIndex] === 'string' ? `${window.location.origin.concat(images[currentIndex]?.replace(/ /g, '%20'))}` : (images?.length > 0 ? URL?.createObjectURL(images[currentIndex]) : '')) : ''}
        src={images?.length > 0
          ? typeof images[currentIndex] === 'string'
            ? `${window.location.origin.concat(images[currentIndex]?.replace(/ /g, '%20'))}`
            : images[currentIndex] instanceof Blob
              ? URL.createObjectURL(images[currentIndex])
              : ''
          : ''}
        alt={`Slide ${currentIndex + 1}`}
        style={{
          width: '100%',
          position: 'relative',
          height: source?.length ? 'calc(100% - 6px)' : '100%',
          borderRadius: '8px 0px 0px 8px',
          objectFit: 'cover',
          top: 0,
          left: 0
        }}
      />
    </Box>
  );
};

export default ImageSlideshow;
