import axios from 'axios';
// import { io } from 'socket.io-client';
import { generateResourceId } from '../utils/generate-resource-id';
import { sign, JWT_SECRET, JWT_EXPIRES_IN } from '../utils/jwt';
import type { User } from '../types/user';
import { wait } from '../utils/wait';
import { nodeApiUrl, nodeApiUrlWs } from './node-server';

const users: User[] = [
  {
    id: '5e86809283e28b96d2d38537',
    avatar: '/static/user-chen_simmons.png',
    email: 'demo@devias.io',
    name: 'Chen Simmons',
    password: 'Password123!'
  }
];

const getAvatar = (username) => {
  if (username === 'admin') {
    return '/static/me.png';
  }
  return '/static/me.png';
};

class AuthApi {
  async login({ email, password }): Promise<{ accessToken: string, wildflyToken: string }> {
    await wait(500);
    const apiHost = process.env.NODE_ENV === 'development' ? nodeApiUrl : '';

    const apiRes = await axios.post(`${apiHost}/login`, { username: email, password }, { headers: { version: 2, 'X-Server-Select': 'api' } });
    const wildflyRes = await axios.post(`${apiHost}/login/wildfly`, { username: email, password }, { headers: { 'X-Server-Select': 'api' } });
    return { accessToken: apiRes.data.result, wildflyToken: wildflyRes.data.result };
  }

  async register({ email, name, password }): Promise<string> {
    await wait(1000);

    return new Promise((resolve, reject) => {
      try {
        // Check if a user already exists
        let user = users.find((_user) => _user.email === email);

        if (user) {
          reject(new Error('User already exists'));
          return;
        }

        user = {
          id: generateResourceId(),
          avatar: null,
          email,
          name,
          password
        };

        users.push(user);

        const accessToken = sign({ userId: user.id }, JWT_SECRET, { expiresIn: JWT_EXPIRES_IN });

        resolve(accessToken);
      } catch (err) {
        console.error('[Auth Api]: ', err);
        reject(new Error('Internal server error'));
      }
    });
  }

  async me(accessToken, wildflyToken): Promise<any> {
    try {
      const meAPiURL = `${process.env.NODE_ENV === 'development' ? nodeApiUrl : ''}/me`;
      if (!accessToken || !wildflyToken) {
        throw new Error('waaah');
      }
      const [apiRes/* , wildflyRes */] = await Promise.all([
        axios.get(`${meAPiURL}`, { headers: { authorization: accessToken, version: 2, 'X-Server-Select': 'api' } }),
        axios.get(`${meAPiURL}/wildfly`, { headers: { 'wildfly-cookie': wildflyToken, 'X-Server-Select': 'api' } }),
      ]);

      return {
        id: apiRes.data.username,
        avatar: getAvatar(apiRes.data.username),
        email: apiRes.data.username,
        name: apiRes.data.name,
        userRole: apiRes.data.role,
        userName: apiRes.data.username,
      };
    } catch (err) {
      throw new Error('Invalid authorization token');
    }
  }
}

export const authApi = new AuthApi();

export const webSocketUrl = () => `${process.env.NODE_ENV === 'development' ? nodeApiUrlWs : `${window.location.protocol?.toLowerCase() === 'https:' ? 'wss' : 'ws'}://${window.location.hostname}/web-socket`}`;
// export const socket = io('http://204.236.175.176:5080');
// export const socket = io(`https://${process.env.NODE_ENV === 'development' ? socketUrl : window.location.hostname}`);
