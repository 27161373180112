/* eslint-disable no-param-reassign */
import axios from 'axios';
import { nodeApiUrl } from 'src/api/node-server';

// export const source = axios.CancelToken.source();
const SdAxios : any = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? nodeApiUrl : undefined,
  headers: {
    Version: 2,
    'X-Server-Select': 'api',
    Authorization: localStorage.getItem('accessToken'),
    'wildfly-cookie': localStorage.getItem('wildflyToken'),
  },
});

SdAxios.interceptors.request.use((config) => {
  const configHeaders = config.headers;
  configHeaders.Authorization = localStorage.getItem('accessToken');
  configHeaders['wildfly-cookie'] = localStorage.getItem('wildflyToken');
  return config;
});

SdAxios.interceptors.response.use(
  (res) => {
    localStorage.setItem('lastRequest', new Date().toString());
    return res;
  },
  (err) => {
    if (err?.response?.status === 401) {
      console.log('401 err : ', err);
      window.location.href = '/login';
    }
    // if (axios.isCancel(err)) {
    //   console.log('Cancel err : ', err);
    // }

    throw err?.response;
  }
);

export default SdAxios;
