/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect } from 'react';
import type { FC } from 'react';
import { useNavigate, useRoutes, Route, Routes } from 'react-router-dom';
import { LicenseInfo } from '@mui/x-license-pro';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { Security, LoginCallback } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { initializeI18n } from './i18n';
import { RTL } from './components/rtl';
import { useSettings } from './contexts/settings-context';
import { useAuth } from './hooks/use-auth';
import routes from './routes';
import { createCustomTheme } from './theme';
import { oktaConfig } from './oktaConfig';

const oktaAuth = new OktaAuth(oktaConfig);
export const App: FC = () => {
  const content = useRoutes(routes);
  const { settings } = useSettings();
  const { isInitialized } = useAuth();

  const navigateTo = useNavigate();
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    navigateTo(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  useEffect(() => {
    // Old license key for mui pro.
    // LicenseInfo.setLicenseKey('c413a81e08e04c5782cbc07828cf5e7cTz02Mzg1MCxFPTE3MTI1MjEyNjg2NjUsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');
    // Renewed license key for mui pro.
    LicenseInfo.setLicenseKey('aa9f6f076e7207fc08bd081d292a1f61Tz04ODI5MyxFPTE3NDQ0OTk5MTMwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');
  }, []);

  useEffect(() => {
    // gtm.initialize(gtmConfig);
    initializeI18n(settings.language);
  }, [settings]);

  const theme = createCustomTheme({
    direction: settings.direction,
    theme: settings.theme
  });

  return (
    <ThemeProvider theme={theme}>
      <RTL direction={settings.direction}>
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
          <CssBaseline />
          {isInitialized && content}
        </Security>
      </RTL>
    </ThemeProvider>
  );
};
