export const applyPagination = (rows: Array<any>, page: number, pageSize?: number): Array<any> => {
  if (page === null) {
    return rows;
  }

  let ps = pageSize;
  if (!ps || ps === 0) {
    ps = 10;
  }

  const paginated = rows.slice(page * ps, page * ps + ps);

  return paginated.length ? paginated : rows;
};
