/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { useEffect, useMemo, useState } from 'react';
import type { FC, ElementType } from 'react';
import { useLocation, matchPath, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import PropTypes from 'prop-types';
import { Box, Divider, Drawer, IconButton, List, Typography, Popover, ListItemText, ListItemIcon, ListItemButton, Dialog, DialogTitle, DialogContent, DialogActions, Button, CircularProgress } from '@material-ui/core';
import { Groups, Home, Person, ChevronLeft, ChevronRight, Logout, MoreVertOutlined, AccountCircle, Close } from '@material-ui/icons';
import { Clients } from 'src/icons/clientsIcon';
import { Storage } from 'src/icons/storage';
import { QuickStart } from 'src/icons/quick-start';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import { projectApi } from 'src/api/project';
import useToast from 'src/hooks/use-toast';
import { InfoIcon } from 'src/icons/infoIcon';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { ReportsIcon } from 'src/icons/reports-icon';
import { Search } from 'src/icons/search';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
// import { socket } from 'src/api/auth';
import Badge from '@mui/material/Badge';
import { QueuedTasksIcon } from 'src/icons/queued-task-icon';
import { DashboardSidebarItem } from './dashboard-sidebar-item';
import { Scrollbar } from './scrollbar';
import { darkPrimary, darkText, lightNeutral } from '../colors';
import { usePopover } from '../hooks/use-popover';
import { useAuth } from '../hooks/use-auth';

interface DashboardSidebarProps {
  onPin: () => void;
  pinned: boolean;
  disabled: boolean
}

interface Item {
  href?: string;
  external?: boolean;
  icon: ElementType;
  items?: Array<{ href: string; title: string; }>
  title: string;
}

export const DashboardSidebar: FC<DashboardSidebarProps> = (props) => {
  const { onPin, pinned, disabled } = props;
  const { pathname } = useLocation();
  const [openedItem, setOpenedItem] = useState<Item | null>(null);
  const [activeItem, setActiveItem] = useState<Item | null>(null);
  const [activeHref, setActiveHref] = useState('');
  const [hovered, setHovered] = useState(false);
  const [anchorRef, open, handleOpen, handleClose] = usePopover();
  const [anchorRefNotification, openNotification, handleOpenNotification, handleCloseNotification] = usePopover();
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [userRole, setUserRole] = useState(localStorage.getItem('userRole'));
  const [sideBarItems, setSideBarItems] = useState([]);
  const [version, setVersion] = useState('');
  const { Toast, handleOpen: handleToastOpen } = useToast();
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [unreadNotificationData, setUnreadNotificationData] = useState<any>({});

  const items: Item[] = [
    {
      icon: Search,
      title: 'Looking for something?',
      href: '/dashboard/global-search'
    },
    {
      icon: QuickStart,
      title: 'Quick Start',
      href: '/dashboard/welcome',
    },
    {
      icon: AccountTreeIcon,
      title: 'Create Workflow',
      href: '/dashboard/projects/create',
    },
    {
      icon: Home,
      title: 'Dashboard',
      items: [
        {
          href: '/dashboard/home/all',
          title: 'All'
        },
        {
          href: '/dashboard/home/active',
          title: 'Active'
        },
        {
          href: '/dashboard/home/failure',
          title: 'Failure'
        },
      ]
    },
    {
      icon: Storage,
      title: 'Storage',
      items: [
        {
          href: '/dashboard/storage/file-system',
          title: 'File System'
        },
        {
          href: '/dashboard/storage/object',
          title: 'Remote'
        },
        {
          href: '/dashboard/storage/tape/tape-devices',
          title: 'Tape'
        },
      ]
    },
    {
      icon: Clients,
      title: 'Data Managers',
      href: '/dashboard/dataManager/general-info'
    },
    {
      icon: Person,
      title: 'Users',
      href: '/dashboard/users'
    },
    {
      icon: Groups,
      title: 'Groups',
      href: '/dashboard/groups'
    },
    {
      icon: QueuedTasksIcon,
      title: 'Queued Tasks',
      href: '/dashboard/queued-tasks'
    },
    {
      icon: ReportsIcon,
      title: 'Reports',
      href: '/dashboard/reports'
    },
    {
      icon: SettingsRoundedIcon,
      title: 'Settings',
      items: [
        {
          href: '/dashboard/settings/email-settings',
          title: 'Email'
        },
        // {
        //   href: '/dashboard/settings/okta-settings',
        //   title: 'Okta Authentication'
        // },
        // {
        //   href: '/dashboard/settings/access-control',
        //   title: 'Access Control'
        // },
        {
          href: '/dashboard/settings/base-urls',
          title: 'Base URLs'
        },
        // {
        //   href: '/dashboard/settings/metadata-template',
        //   title: 'Templates'
        // },
        {
          href: '/dashboard/settings/personalization',
          title: 'Personalization'
        }
      ]
    },
    // {
    //   icon: NotificationImportantIcon,
    //   title: 'Notifications',
    //   items: [
    //     {
    //       href: '/dashboard/notifications/alerts-details',
    //       title: 'Alerts & Details'
    //     },
    //     {
    //       href: '/dashboard/notifications/settings',
    //       title: 'Settings'
    //     },
    //   ]
    // },
  ];

  const getVersion = async () => {
    try {
      const result = await projectApi.getSdnaVersion();
      setVersion(result?.version);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getVersion();
  }, []);

  const handleOpenItem = (item: Item): void => {
    if (openedItem === item) {
      setOpenedItem(null);
      return;
    }
    setOpenedItem(item);
  };

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose();
      await logout();
      navigate('/');
    } catch (err) {
      console.error(err);
      toast.error('Something went wrong');
    }
  };

  const handleSwitchUI = async () => {
    const { origin } = window.location;
    const oldGuiPath = origin.replace('https', 'http');
    window.open(oldGuiPath);
    //   try {
    //     handleClose();
    //     const result = await projectApi.getOldUIData();
    //     if (result.success === '1') {
    //       setIsModelOpen(true);
    //       clearCache();
    //       setTimeout(() => {
    //         const { origin } = window.location;
    //         console.log('Origin : ', origin);
    //         window.open(origin);
    //         handleLogout();
    //       }, 10000);
    //     } else {
    //       handleToastOpen({
    //         status: false,
    //         message: result?.message
    //       });
    //     }
    //   } catch (err) {
    //     handleToastOpen({
    //       status: false,
    //       message: err
    //     });
    //   }
  };

  const handleSwitchGUI = () => {
    setIsModelOpen(false);
  };

  const testItems = useMemo(() => {
    const finalItems1 = items && items?.filter((item) => {
      if (localStorage.getItem('userRole') === 'SDNA_NormalUser') {
        return !['Settings', 'Users', 'Groups', 'Storage', 'Data Managers'].includes(item.title);
      }
      return item;
    });
    return finalItems1;
  }, [userRole]);

  useEffect(() => {
    testItems && testItems.length > 0 && setSideBarItems(testItems);// when the URL changes, set active item, href and opened item to item corresponding current URL
    items?.forEach((item) => {
      if (item.items) {
        for (let index = 0; index < item.items.length; index++) {
          const active = matchPath({ path: item.items[index].href, end: true }, pathname);

          if (active) {
            setActiveItem(item);
            setActiveHref(item.items[index].href);
            setOpenedItem(item);
            break;
          }
        }
      } else {
        const active = !!matchPath({ path: item.href, end: true }, pathname);

        if (active) {
          setActiveItem(item);
          setOpenedItem(item);
        }
      }
    });
  }, [pathname]);

  useEffect(() => {
    if (pathname === '/dashboard/storage/tape/tape-devices' && openedItem?.title === 'Storage') {
      localStorage.setItem('navigatedFrom', 'menu');
    }
  }, [pathname]);

  // socket.connect();
  // socket.on('notification', (data) => {
  //   handleToastOpen({
  //     status: true,
  //     message: data?.message
  //   });
  // });

  // socket.on('unread notifications', (data) => {
  //   setUnreadNotificationData(data);
  // });

  return (
    <>
      <Toast />
      <Drawer
        open
        sx={{
          zIndex: 1000,
          maxWidth: '100%',
          width: '100%'
        }}
        variant="permanent"
        PaperProps={{
          // onMouseOver: () => (!disabled ? setHovered(true) : null),
          onMouseLeave: () => { setHovered(false); },
          sx: {
            backgroundColor: 'background.paper',
            height: '100%',
            overflowX: 'hidden',
            top: 0,
            // transition: 'width 250ms ease-in-out',
            width: pinned ? 270 : 73,
            '& .simplebar-content': {
              height: '100%'
            },
            // '&:hover': {
            //   width: !disabled ? 270 : 73,
            //   '& span, p': {
            //     display: 'flex'
            //   }
            // }
          }
        }}
      >
        <Scrollbar
          style={{
            display: 'flex',
            flex: 1,
            overflowX: 'hidden',
            overflowY: 'auto',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              width: '100%',
              p: 2
            }}
          >
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Box
                onClick={() => !disabled && navigate('home/all')}
                width="120px"
                sx={{
                  cursor: 'pointer',
                  height: '52px',
                  alignItems: 'center',
                  display: {
                    md: 'flex',
                    xs: 'none'
                  },
                  p: '0px 10px 12px 10px',
                }}
              >
                <Box
                  component="img"
                  sx={{
                    height: 30,
                    width: 22,
                    marginRight: '8px',
                  }}
                  alt="app-logo"
                  src="/static/app-logo.png"
                />

                {
                  (hovered || pinned) && (
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography fontSize="12px" fontWeight="400">
                        StorageDNA
                      </Typography>

                      <Typography color="primary" fontSize="16px" fontWeight="600" sx={{ lineHeight: 'normal' }}>
                        DNAfabric
                      </Typography>
                    </Box>
                  )
                }
              </Box>
              {/* <Box>
                <IconButton onClick={handleOpenNotification} ref={anchorRefNotification}>
                  {
                    unreadNotificationData?.unreadCount ? (
                      <Badge
                        color="error"
                        variant="dot"
                      >
                        <NotificationImportantIcon />
                      </Badge>
                    ) : <NotificationImportantIcon />
                  }
                </IconButton>
              </Box> */}
            </Box>
            <Divider sx={{ marginBottom: '10px', borderColor: 'gray' }} />
            <List
              disablePadding sx={{
                overflow: 'hidden auto',
                '&::-webkit-scrollbar': {
                  width: 0,
                },
              }}
            >
              {sideBarItems && sideBarItems.map((item) => (
                <DashboardSidebarItem
                  active={activeItem?.title === item.title}
                  activeHref={activeHref}
                  key={item.title}
                  onOpen={() => handleOpenItem(item)}
                  open={openedItem?.title === item.title && (hovered || pinned)}
                  pinned={pinned}
                  disabled={disabled}
                  {...item}
                />
              ))}
            </List>
            <Box sx={{ flexGrow: 1 }} />

            <Box
              ref={anchorRef}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                py: '8px',
              }}
            >
              {
                  (hovered || pinned) && (
                    <Box sx={{ pl: '10px' }}>
                      <Typography
                        fontSize="12px" fontWeight="400" sx={{ color: lightNeutral[500] }}
                      >
                        {localStorage.getItem('Name')}
                      </Typography>

                      <Typography fontSize="14px" fontWeight="500">
                        {localStorage.getItem('userName')}
                      </Typography>
                    </Box>
                  )
                }

              <IconButton onClick={handleOpen}>
                {
                    (hovered || pinned) ? (
                      <MoreVertOutlined />
                    ) : (
                      <AccountCircle />
                    )
                  }
              </IconButton>
            </Box>
            <Divider sx={{ borderColor: 'gray' }} />
            <Box display="flex" justifyContent="space-between" alignItems="center" paddingY={0.5}>
              {(hovered || pinned) && (
                <Box>
                  <Typography sx={{ lineHeight: '1.5', fontSize: '12px', fontWeight: 400, color: '#9aa1b7', margin: '8px 0px 2px', pl: '10px' }}>
                    ver.
                    {' '}
                    {version?.slice(0, -5)}
                  </Typography>
                  <Typography fontSize="10px" fontWeight="500" variant="caption" sx={{ pl: '10px', color: 'rgba(126, 127, 134, 0.85)' }}>
                    ©
                    {' '}
                    {new Date().getFullYear()}
                    {' '}
                    StorageDNA - DNAfabric
                  </Typography>
                </Box>
              )}
              <IconButton onClick={!disabled && onPin}>
                {pinned ? <ChevronLeft /> : <ChevronRight />}
              </IconButton>
            </Box>
          </Box>
        </Scrollbar>
      </Drawer>

      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: (hovered || pinned) ? 'right' : 'left',
          vertical: 'bottom'
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: {
            width: 250,
            display: 'flex',
            flexDirection: 'column',
            marginTop: '10px',
          }
        }}
      >
        <List>
          <ListItemButton onClick={handleSwitchUI}>
            <ListItemIcon>
              <KeyboardReturnIcon />
            </ListItemIcon>
            <ListItemText primary="Switch GUI" />
          </ListItemButton>
          <ListItemButton onClick={handleLogout}>
            <ListItemIcon>
              <Logout />
            </ListItemIcon>
            <ListItemText primary="Log out" />
          </ListItemButton>
        </List>
      </Popover>

      <Popover
        open={openNotification}
        anchorEl={anchorRefNotification.current}
        onClose={handleCloseNotification}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{
          sx: {
            height: '100%',
            maxHeight: 'calc(100% - 1px)',
            marginLeft: '17px',
            marginTop: '-15px',
            width: '400px',
            backgroundColor: 'rgba(27, 27, 35, 1)',
            border: '1px solid rgba(101, 103, 110, 1)',
          }
        }}
      >
        <Box height="100%">
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#2A2F3C', height: '50px', width: '400px', p: '0px 16px' }}>
            <Typography variant="subtitle2">Notification Summary</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton onClick={() => { navigate('/dashboard/notifications/settings'); handleCloseNotification(); }}>
                <SettingsRoundedIcon sx={{ color: darkText.secondary, height: '20px', width: '20px' }} />
              </IconButton>
              <Button variant="text" size="medium" color="inherit" sx={{ p: 0, color: darkText.secondary }}>
                Clear All
              </Button>
              <IconButton onClick={handleCloseNotification}>
                <Close sx={{ color: darkText.secondary }} />
              </IconButton>
            </Box>
          </Box>
          <Box sx={{ p: 2 }}>
            {
            unreadNotificationData?.unreadNotifications?.map((item) => (
              <Box sx={{ p: '14px 3px 14px 16px', borderBottom: '1px solid rgba(126, 127, 134, 1)' }} display="flex" justifyContent="space-between">
                <Typography variant="body2" color={darkText.secondary}>{item?.description}</Typography>
                <Close sx={{ color: darkText.secondary }} />
              </Box>
            ))
            }
          </Box>
        </Box>
      </Popover>

      <Dialog
        open={isModelOpen} onClose={() => handleSwitchGUI()}
      >
        <DialogTitle>
          <Box display="flex" gap={2} alignItems="center">
            <InfoIcon
              color="info"
              fontSize="large"
            />
            <Typography variant="inherit">
              Switching GUI
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            You will be logged out and the old GUI will be opened in a new window.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button startIcon={<CircularProgress sx={{ color: darkPrimary.contrast }} size="1rem" />}>Switching</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

DashboardSidebar.propTypes = {
  onPin: PropTypes.func,
  pinned: PropTypes.bool,
  disabled: PropTypes.bool,
};
