import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { LoginCallback } from '@okta/okta-react';
import { AuthGuard } from './components/auth-guard';
import { GuestGuard } from './components/guest-guard';
import { Customer } from './containers/customer';
import { LoadingScreen } from './components/loading-screen';
import { DashboardLayout } from './containers/dashboard-layout';
// import { Home } from './containers/home';
// import { MainLayout } from './containers/main-layout';
import { Organization } from './containers/organization';
import { Product } from './containers/product';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// Not found pages
const NotFound = Loadable(
  lazy(() => import('./containers/not-found').then((module) => ({ default: module.NotFound })))
);

const StubRedirection = Loadable(
  lazy(() => import('./containers/stub-redirection').then((module) => ({ default: module.StubRedirection })))
);

// Auth pages
const Login = Loadable(
  lazy(() => import('./containers/login').then((module) => ({ default: module.Login })))
);

const PasswordRecovery = Loadable(
  lazy(() => import('./containers/password-recovery').then((module) => ({
    default: module.PasswordRecovery,
  })))
);
const PasswordReset = Loadable(
  lazy(() => import('./containers/password-reset').then((module) => ({ default: module.PasswordReset })))
);
const Register = Loadable(
  lazy(() => import('./containers/register').then((module) => ({ default: module.Register })))
);
const VerifyCode = Loadable(
  lazy(() => import('./containers/verify-code').then((module) => ({ default: module.VerifyCode })))
);

// Home Layout
const Dashboard = Loadable(lazy(
  () => import('./components/dashboard/dashboard').then((module) => ({ default: module.Dashboard }))
));
const WelcomeScreen = Loadable(lazy(
  () => import('./containers/welcome-screen').then((module) => ({ default: module.WelcomeScreen }))
));

// Dashboard pages

// Storage pages
const StorageFileSystem = Loadable(
  lazy(() => import('./components/storage/file-system/file-system').then((module) => ({
    default: module.FileSystem,
  })))
);
const StorageObjectConfig = Loadable(
  lazy(() => import('./components/storage/object-config/object-config').then((module) => ({
    default: module.ObjectConfig,
  })))
);
const StorageTape = Loadable(
  lazy(() => import('./components/storage/tape/tape').then((module) => ({
    default: module.Tape,
  })))
);
const StorageTapeDevices = Loadable(
  lazy(() => import('./components/storage/tape/tape-devices/tape-devices').then((module) => ({
    default: module.TapeDevices,
  })))
);
const StorageTapeAllTapes = Loadable(
  lazy(() => import('./components/storage/tape/all-tapes/all-tapes').then((module) => ({
    default: module.AllTapes,
  })))
);
// Clients pages
const ClientsInfo = Loadable(
  lazy(() => import('./components/clients/index').then((module) => ({ default: module.ClientsInfo })))
);

// Users pages
const Users = Loadable(
  lazy(() => import('./components/users/users').then((module) => ({ default: module.Users })))
);

// Groups pages
const Groups = Loadable(
  lazy(() => import('./components/groups/groups').then((module) => ({ default: module.Groups })))
);

// Settings pages
const EmailSettings = Loadable(
  lazy(() => import('./components/settings/email-settings').then((module) => ({ default: module.EmailSettings })))
);

// const OktaSettings = Loadable(
//   lazy(() => import('./components/settings/okta-settings').then((module) => ({ default: module.OktaSettings })))
// );

// const AccessControl = Loadable(
//   lazy(() => import('./components/settings/access-control').then((module) => ({ default: module.AccessControl })))
// );

const BaseURLs = Loadable(
  lazy(() => import('./components/settings/base-urls').then((module) => ({ default: module.BaseURLs })))
);

// const MetadataTemplate = Loadable(
//   lazy(() => import('./components/settings/templates').then((module) => ({ default: module.Template })))
// );

const Personalization = Loadable(
  lazy(() => import('./components/settings/personalization').then((module) => ({ default: module.Personalization })))
);

// const AlertsDetails = Loadable(
//   lazy(() => import('./components/notifications/alerts-details').then((module) => ({ default: module.AlertsDetails })))
// );

// const AlertsSettings = Loadable(
//   lazy(() => import('./components/notifications/settings').then((module) => ({ default: module.AlertsSettings })))
// );

const ClientsGeneralInfo = Loadable(
  lazy(() => import('./components/clients/general-info/general-info').then((module) => ({
    default: module.GeneralInfo,
  })))
);
const ClientsLicenseInfo = Loadable(
  lazy(() => import('./components/clients/license-info/license-info').then((module) => ({
    default: module.LicenseInfo,
  })))
);

const Customers = Loadable(
  lazy(() => import('./containers/customers').then((module) => ({ default: module.Customers })))
);
const CustomerActivity = Loadable(
  lazy(() => import('./containers/customer-activity').then((module) => ({
    default: module.CustomerActivity,
  })))
);
const CustomerOrders = Loadable(
  lazy(() => import('./containers/customer-orders').then((module) => ({ default: module.CustomerOrders })))
);
const CustomerSummary = Loadable(
  lazy(() => import('./containers/customer-summary').then((module) => ({ default: module.CustomerSummary })))
);

const Projects = Loadable(
  lazy(() => import('./containers/projects').then((module) => ({ default: module.Projects })))
);

const ProjectIndex = Loadable(
  lazy(() => import('./containers/project-index').then((module) => ({ default: module.ProjectIndex })))
);

const ProjectBrowseAndSearch = Loadable(
  lazy(() => import('./containers/project-browse-and-search').then((module) => ({
    default: module.ProjectBrowseAndSearch,
  })))
);
// const ProjectBrowseAndSearchMetadata = Loadable(
//   lazy(() => import('./containers/project-browse-and-search-metadata').then((module) => ({
//     default: module.ProjectBrowseAndSearchMetadata,
//   })))
// );
const ProjectSearchClips = Loadable(
  lazy(() => import('./containers/project-search-clips').then((module) => ({
    default: module.ProjectSearchClips,
  })))
);
const ProjectStorageAnalysis = Loadable(
  lazy(() => import('./containers/project-storage-analysis').then((module) => ({
    default: module.ProjectStorageAnalysis,
  })))
);
const ProjectProbeAnalysis = Loadable(
  lazy(() => import('./containers/project-probe-analysis').then((module) => ({
    default: module.ProjectProbeAnalysis,
  })))
);
const ProjectDuplicationAnalysis = Loadable(
  lazy(() => import('./containers/project-duplication-analysis').then((module) => ({
    default: module.ProjectDuplicationAnalysis,
  })))
);
const ProjectTickets = Loadable(
  lazy(() => import('./containers/project-tickets').then((module) => ({ default: module.ProjectTickets })))
);
const ProjectSummary = Loadable(
  lazy(() => import('./containers/project-summary').then((module) => ({ default: module.ProjectSummary })))
);
const ProjectHistory = Loadable(
  lazy(() => import('./containers/project-history').then((module) => ({ default: module.ProjectHistory })))
);
const ProjectSchedule = Loadable(
  lazy(() => import('./containers/project-schedule').then((module) => ({ default: module.ProjectSchedule })))
);
const ProjectProgress = Loadable(
  lazy(() => import('./containers/project-progress').then((module) => ({ default: module.ProjectProgress })))
);
const ProjectOption = Loadable(
  lazy(() => import('./containers/project-options').then((module) => ({ default: module.ProjectOption })))
);
const ProjectAutomationTicketing = Loadable(
  lazy(() => import('./containers/project-automation-ticketing').then((module) => ({ default: module.ProjectAutomationTicketing })))
);
const ProjectTapes = Loadable(
  lazy(() => import('./containers/project-tapes').then((module) => ({ default: module.ProjectTapes })))
);
const ProjectCreateSelectWorkflow = Loadable(
  lazy(() => import('./components/project/workflow/project-create-select-workflow').then((module) => ({
    default: module.ProjectCreateSelectWorkflow,
  })))
);
const ProjectCreate = Loadable(
  lazy(() => import('./components/project/create/project-create').then((module) => ({
    default: module.ProjectCreate,
  })))
);

const Order = Loadable(
  lazy(() => import('./containers/order').then((module) => ({ default: module.Order })))
);
const Orders = Loadable(
  lazy(() => import('./containers/orders').then((module) => ({ default: module.Orders })))
);

const Invoices = Loadable(
  lazy(() => import('./containers/invoices').then((module) => ({ default: module.Invoices })))
);
const InvoiceCreate = Loadable(
  lazy(() => import('./containers/invoice-create').then((module) => ({ default: module.InvoiceCreate })))
);
const InvoiceSummary = Loadable(
  lazy(() => import('./containers/invoice').then((module) => ({ default: module.Invoice })))
);
const InvoicePreview = Loadable(
  lazy(() => import('./containers/invoice-preview').then((module) => ({ default: module.InvoicePreview })))
);

const Products = Loadable(
  lazy(() => import('./containers/products').then((module) => ({ default: module.Products })))
);
const ProductAnalytics = Loadable(
  lazy(() => import('./containers/product-analytics').then((module) => ({
    default: module.ProductAnalytics,
  })))
);
const ProductInventory = Loadable(
  lazy(() => import('./containers/product-inventory').then((module) => ({
    default: module.ProductInventory,
  })))
);
const ProductSummary = Loadable(
  lazy(() => import('./containers/product-summary').then((module) => ({ default: module.ProductSummary })))
);
const OrganizationBilling = Loadable(
  lazy(() => import('./containers/organization-billing').then((module) => ({
    default: module.OrganizationBilling,
  })))
);
const OrganizationGeneral = Loadable(
  lazy(() => import('./containers/organization-general').then((module) => ({
    default: module.OrganizationGeneral,
  })))
);
const OrganizationTeam = Loadable(
  lazy(() => import('./containers/organization-team').then((module) => ({
    default: module.OrganizationTeam,
  })))
);

const ComponentsCardHeadings = Loadable(
  lazy(() => import('./containers/components-card-headers').then((module) => ({
    default: module.ComponentsCardHeaders,
  })))
);
const ComponentsDataStates = Loadable(
  lazy(() => import('./containers/components-data-states').then((module) => ({
    default: module.ComponentsDataStates,
  })))
);
const ComponentsDataStats = Loadable(
  lazy(() => import('./containers/components-data-stats').then((module) => ({
    default: module.ComponentsDataStats,
  })))
);
const ComponentsImageUploader = Loadable(
  lazy(() => import('./containers/components-image-uploader').then((module) => ({
    default: module.ComponentsImageUploader,
  })))
);
const ComponentsLists = Loadable(
  lazy(() => import('./containers/components-lists').then((module) => ({ default: module.ComponentsLists })))
);
const ComponentsOnboarding = Loadable(
  lazy(() => import('./containers/components-onboarding').then((module) => ({
    default: module.ComponentsOnboarding,
  })))
);
const ComponentsPageHeadings = Loadable(
  lazy(() => import('./containers/components-page-headers').then((module) => ({
    default: module.ComponentsPageHeaders,
  })))
);

const FoundationBlankPage = Loadable(
  lazy(() => import('./containers/foundation-blank-page').then((module) => ({
    default: module.FoundationBlankPage,
  })))
);
const FoundationButtons = Loadable(
  lazy(() => import('./containers/foundation-buttons').then((module) => ({
    default: module.FoundationButtons,
  })))
);
const FoundationColors = Loadable(
  lazy(() => import('./containers/foundation-colors').then((module) => ({
    default: module.FoundationColors,
  })))
);
const FoundationInputs = Loadable(
  lazy(() => import('./containers/foundation-inputs').then((module) => ({
    default: module.FoundationInputs,
  })))
);
const FoundationShadows = Loadable(
  lazy(() => import('./containers/foundation-shadows').then((module) => ({
    default: module.FoundationShadows,
  })))
);
const FoundationTables = Loadable(
  lazy(() => import('./containers/foundation-tables').then((module) => ({
    default: module.FoundationTables,
  })))
);
const FoundationTypography = Loadable(
  lazy(() => import('./containers/foundation-typography').then((module) => ({
    default: module.FoundationTypography,
  })))
);
const QueuedTasks = Loadable(
  lazy(() => import('./containers/queued-tasks').then((module) => ({ default: module.QueuedTasks })))
);
const Reports = Loadable(
  lazy(() => import('./components/catalog-run-reports/reports').then((module) => ({ default: module.Reports })))
);
const GlobalSearch = Loadable(
  lazy(() => import('./components/global-search/global-search').then((module) => ({ default: module.GlobalSearch })))
);

// Docs pages
const Docs = Loadable(
  lazy(() => import('./containers/docs').then((module) => ({ default: module.Docs })))
);

const routes = [
  {
    path: '',
    element: (
      <GuestGuard>
        <Login />
      </GuestGuard>
    ),
  },
  {
    path: 'login',
    element: (
      <GuestGuard>
        <Login />
      </GuestGuard>
    ),
  },
  {
    path: 'oktalogin',
    element: (
      <LoginCallback />
    ),
  },
  {
    path: 'register',
    element: (
      <GuestGuard>
        <Register />
      </GuestGuard>
    ),
  },
  {
    path: 'verify-code',
    element: (
      <GuestGuard>
        <VerifyCode />
      </GuestGuard>
    ),
  },
  {
    path: 'password-recovery',
    element: (
      <GuestGuard>
        <PasswordRecovery />
      </GuestGuard>
    ),
  },
  {
    path: 'password-reset',
    element: <PasswordReset />,
  },
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: 'welcome',
        element: (
          <WelcomeScreen />
        )
      },
      {
        path: '',
        element: (
          <Navigate
            to="/dashboard/home/all"
            replace
          />
        )
      },
      {
        path: 'home',
        element: <Dashboard />,
        children: [
          {
            path: 'all',
            element: <Dashboard />
          },
          {
            path: 'active',
            element: <Dashboard />
          },
          {
            path: 'failure',
            element: <Dashboard />
          }
        ]
      },
      {
        path: 'storage',
        children: [
          {
            path: 'file-system',
            element: <StorageFileSystem />
          },
          {
            path: 'object',
            element: <StorageObjectConfig />,
          },
          {
            path: 'tape',
            element: <StorageTape />,
            children: [
              {
                path: 'tape-devices',
                element: <StorageTapeDevices />,
              },
              {
                path: 'all-tapes',
                element: <StorageTapeAllTapes />,
              },
            ],
          },
        ],
      },
      {
        path: 'dataManager',
        element: <ClientsInfo />,
        children: [
          {
            path: 'general-info',
            element: <ClientsGeneralInfo />,
          },
          {
            path: 'license-info',
            element: <ClientsLicenseInfo />,
          },
        ],
      },
      {
        path: 'users',
        element: <Users />,
      },
      {
        path: 'groups',
        element: <Groups />,
      },
      {
        path: 'global-search',
        element: <GlobalSearch />,
      },
      {
        path: 'queued-tasks',
        element: <QueuedTasks />,
      },
      {
        path: 'reports',
        element: <Reports />,
      },
      {
        path: 'customers',
        children: [
          {
            path: '',
            element: <Customers />
          },
          {
            path: ':customerId',
            element: <Customer />,
            children: [
              {
                path: '',
                element: <CustomerSummary />
              },
              {
                path: 'activity',
                element: <CustomerActivity />,
              },
              {
                path: 'orders',
                element: <CustomerOrders />,
              },
            ],
          },
        ],
      },
      {
        path: 'projects',
        children: [
          {
            path: '',
            element: <Projects />
          },
          {
            path: 'create',
            children: [
              {
                path: '',
                element: <ProjectCreateSelectWorkflow />
              },
              {
                path: ':workflow',
                element: <ProjectCreate />,
              },
            ],
          },
          {
            path: ':jobGuid',
            element: <ProjectIndex />,
            children: [
              {
                path: 'Folders',
                element: <ProjectSummary />
              },
              {
                path: 'browse&search',
                element: <ProjectBrowseAndSearch />,
                children: [
                  {
                    path: '?path=:searchedPath&filename=:searchFileName',
                    element: <ProjectBrowseAndSearch />
                  }
                ],
              },
              // {
              //   path: 'browse&searchMetadata',
              //   element: <ProjectBrowseAndSearchMetadata />,
              //   children: [
              //     {
              //       path: '?path=:searchedMetadataPath&filename=:searchMetadataFileName',
              //       element: <ProjectBrowseAndSearchMetadata />
              //     }
              //   ],
              // },
              {
                path: 'searchClips',
                element: <ProjectSearchClips />,
              },
              {
                path: 'StorageAnalysis',
                element: <ProjectStorageAnalysis />
              },
              {
                path: 'probeAnalysis',
                element: <ProjectProbeAnalysis />
              },
              {
                path: 'duplicationAnalysis',
                element: <ProjectDuplicationAnalysis />
              },
              {
                path: 'tickets',
                element: <ProjectTickets />
              },
              {
                path: 'history',
                element: <ProjectHistory />
              },
              {
                path: 'schedule',
                element: <ProjectSchedule />
              },
              {
                path: 'progress',
                element: <ProjectProgress />
              },
              {
                path: 'automation',
                element: <ProjectAutomationTicketing />
              },
              {
                path: 'tapes',
                element: <ProjectTapes />
              },
              {
                path: 'options',
                element: <ProjectOption />
              },
            ]
          }
        ]
      },
      {
        path: 'orders',
        children: [
          {
            path: '',
            element: <Orders />
          },
          {
            path: ':orderId',
            element: <Order />,
          },
        ],
      },
      {
        path: 'organization',
        element: <Organization />,
        children: [
          {
            path: '',
            element: <OrganizationGeneral />
          },
          {
            path: 'team',
            element: <OrganizationTeam />
          },
          {
            path: 'billing',
            element: <OrganizationBilling />
          }
        ]
      },
      {
        path: 'products',
        children: [
          {
            path: '',
            element: <Products />
          },
          {
            path: ':productId',
            element: <Product />,
            children: [
              {
                path: '',
                element: <ProductSummary />
              },
              {
                path: 'analytics',
                element: <ProductAnalytics />,
              },
              {
                path: 'inventory',
                element: <ProductInventory />,
              },
            ],
          },
        ],
      },
      {
        path: 'invoices',
        children: [
          {
            path: '',
            element: <Invoices />
          },
          {
            path: 'create',
            element: <InvoiceCreate />,
          },
          {
            path: ':invoiceId',
            children: [
              {
                path: '',
                element: <InvoiceSummary />
              },
              {
                path: 'preview',
                element: <InvoicePreview />
              }
            ]
          }
        ]
      },
      {
        path: 'components',
        children: [
          {
            path: 'onboarding',
            element: <ComponentsOnboarding />,
          },
          {
            path: 'empty-states',
            element: <ComponentsDataStates />,
          },
          {
            path: 'page-headings',
            element: <ComponentsPageHeadings />,
          },
          {
            path: 'card-headings',
            element: <ComponentsCardHeadings />,
          },
          {
            path: 'image-uploader',
            element: <ComponentsImageUploader />,
          },
          {
            path: 'stats',
            element: <ComponentsDataStats />,
          },
          {
            path: 'property-lists',
            element: <ComponentsLists />,
          },
        ],
      },
      {
        path: 'foundation',
        children: [
          {
            path: 'colors',
            element: <FoundationColors />,
          },
          {
            path: 'typography',
            element: <FoundationTypography />,
          },
          {
            path: 'shadows',
            element: <FoundationShadows />,
          },
          {
            path: 'buttons',
            element: <FoundationButtons />,
          },
          {
            path: 'inputs',
            element: <FoundationInputs />,
          },
          {
            path: 'tables',
            element: <FoundationTables />,
          },
          {
            path: 'blank-page',
            element: <FoundationBlankPage />,
          },
        ],
      },
      {
        path: 'settings',
        children: [
          {
            path: 'email-settings',
            element: <EmailSettings />,
          },
          // {
          //   path: 'okta-settings',
          //   element: <OktaSettings />,
          // },
          // {
          //   path: 'access-control',
          //   element: <AccessControl />,
          // },
          {
            path: 'base-urls',
            element: <BaseURLs />,
          },
          // {
          //   path: 'metadata-template',
          //   element: <MetadataTemplate />,
          // },
          {
            path: 'personalization',
            element: <Personalization />,
          }
        ],
      },
      // {
      //   path: 'notifications',
      //   children: [
      //     {
      //       path: 'alerts-details',
      //       element: <AlertsDetails />,
      //     },
      //     {
      //       path: 'settings',
      //       element: <AlertsSettings />,
      //     },
      //   ],
      // },
    ],
  },
  {
    path: 'docs',
    children: [
      {
        path: '',
        element: (
          <Navigate
            to="/docs/overview/welcome"
            replace
          />
        )
      },
      {
        path: '*',
        element: <Docs />,
      },
    ],
  },
  // ?panename=myarchives&projectname=Disk-Copy-Sanity-New-CDM&foldername=/media/disk-source-CDM/automation_source/Testing_Data/stub-cdm&filename=BonefishCliff.png
  {
    path: 'pages/bluespace/DNA360SpaceLoader.jsp',
    element: <StubRedirection />,
  },
  {
    path: 'pages/bluespace/DNA360SpaceLoader.jsp',
    element: <StubRedirection />,
  },
  {
    path: '*',
    element: <NotFound />,
  },
];

export default routes;
